import type { SupportBase } from "../types";

export default {
  title: "Support",
  subtitle: "Comment pouvons-nous vous aider ?",
  description:
    "Trouvez des réponses aux questions fréquentes et obtenez le support dont vous avez besoin.",
  productDocumentation: {
    title: "Documentation Produit",
    description:
      "Nous offrons un support technique complet pour tous nos produits. Notre équipe compétente est prête à vous aider pour toute question ou dépannage. Pour obtenir de l'aide sur les détails des produits, veuillez consulter notre Base de Connaissances ou contacter directement notre équipe de support technique.",
    contact: {
      0: {
        title: "Contacter le Support Technique",
        email: "technical_support@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Support Commande et Paiement",
    description:
      "Nous visons à rendre le processus de commande simple et direct. Que vous achetiez un seul article ou passiez une commande en gros, nous proposons diverses options adaptées à vos besoins.",
    contact: {
      0: {
        title: "Contacter le Support Technique",
        email: "technical_support@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Expédition et Livraison",
    description:
      "Nos produits sont expédiés dans le monde entier avec un accent sur une livraison rapide et fiable. Nous collaborons avec des transporteurs de confiance pour garantir que votre commande arrive dans des conditions optimales.",
    tableItems: {
      1: {
        key: "Options d'Expédition",
        value: "À confirmer",
      },
      2: {
        key: "Suivi de Commande",
        value: "Suivez votre expédition en temps réel une fois qu'elle est envoyée.",
      },
      3: {
        key: "Expédition Internationale",
        value: "À confirmer",
      },
    },
    contact: {
      0: {
        title: "Contacter le Support Technique",
        email: "technical_support@genotic.com",
      },
    },
  },
  contact: {
    title: "Contactez-nous",
    subtitle: "Vous ne trouvez pas de réponse à votre question ? Contactez-nous.",
    email: "contact@genotic.com",
  },
  faq: {
    sections: {
      storageAndHandling: {
        title: "Stockage et Manipulation",
        icon: "akar-icons:headphone",
        items: [
          {
            question: "Quelles sont les conditions de stockage recommandées pour vos produits ?",
            answer:
              "Tous les produits lyophilisés doivent être conservés à température ambiante. Pour les produits sous forme liquide, un stockage à 4 °C ou au congélateur est recommandé. Veuillez consulter la fiche produit détaillée pour les instructions de stockage spécifiques à chaque produit.",
          },
          {
            question: "Comment dois-je manipuler les produits sensibles à la température ?",
            answer:
              "Veuillez suivre les instructions de stockage spécifiques fournies dans la fiche produit. En général, les produits liquides doivent être conservés à 4 °C ou au congélateur pour maintenir leur stabilité. Un stockage approprié garantit l'efficacité et la longévité du produit.",
          },
          {
            question:
              "Quelle est la durée de conservation de vos produits et comment est-elle déterminée ?",
            answer:
              "La durée de conservation de nos produits dépend de la méthode de production et des conditions de stockage. Pour les produits lyophilisés, la durée de conservation est d'environ 12 mois lorsqu'ils sont conservés dans un endroit frais et sombre. Pour les produits congelés, des informations spécifiques sur la durée de conservation seront fournies. Comme nos protéines sont nouvellement développées, nous menons des études de stabilité continues pour fournir des données précises sur la durée de conservation.",
          },
          {
            question:
              "Comment puis-je obtenir les Certificats d'Analyse (COA) ou les Fiches de Données de Sécurité (FDS) pour vos produits ?",
            answer:
              "Tous les documents nécessaires, y compris la Fiche de Données de Sécurité, la Fiche Technique et le Certificat d'Analyse, sont directement disponibles sur la page de chaque produit. Ces documents peuvent être consultés et téléchargés pour votre commodité.",
          },
          {
            question:
              "Puis-je accéder aux protocoles, guides d'utilisation ou à la documentation technique de vos produits ?",
            answer:
              "Pour le moment, nous ne proposons pas de protocoles ou de guides d'utilisation, mais nous prévoyons de les ajouter à l'avenir. Cependant, nous fournissons de la documentation technique et une collection de données agrégées à partir d'articles scientifiques, qui peuvent être trouvées sur les pages des produits concernés. Nous nous engageons à soutenir vos besoins de recherche et mettrons continuellement à jour nos ressources.",
          },
          {
            question:
              "Quelles mesures de contrôle qualité sont en place pour assurer la cohérence des produits ?",
            answer:
              "Nous mettons en œuvre des mesures de contrôle qualité rigoureuses pour chaque lot de produits. Cela inclut l'électrophorèse sur gel pour évaluer la pureté et le poids moléculaire, l'Interférométrie Bio-couche (BLI) pour mesurer les interactions protéiques, et l'analyse par Résonance Plasmonique de Surface (SPR) pour certaines protéines. Pour les enzymes, nous effectuons des tests d'activité enzymatique spécifiques à chaque enzyme, comme détaillé dans la fiche de données de sécurité du produit. De plus, nous réalisons des analyses SDS-PAGE et, pour certaines protéines, des analyses Western Blot pour garantir la plus haute qualité.",
          },
        ],
      },
      performance: {
        title: "Performance et Support",
        icon: "akar-icons:headphone",
        items: [
          {
            question: "Que dois-je faire si un produit ne fonctionne pas comme prévu ?",
            answer:
              "Veuillez contacter notre équipe de support technique pour obtenir de l'aide. Nous sommes là pour vous aider à résoudre tout problème et assurer une performance optimale de nos produits.",
          },
          {
            question:
              "Proposez-vous des formulations personnalisées ou des modifications de vos produits ?",
            answer:
              'Oui, nous proposons des formulations personnalisées et des modifications de nos produits. Toutes les informations pertinentes se trouvent dans la section "Services" de notre site web. Pour plus d\'informations, veuillez nous contacter par email à contact@genotic.com',
          },
          {
            question:
              "Vos produits sont-ils validés pour des applications de recherche spécifiques (par exemple, immunodosages, diagnostics) ?",
            answer:
              "Nous sommes actuellement en train de valider nos produits pour des applications spécifiques. Dans un futur proche, nous fournirons des informations détaillées indiquant quels produits conviennent aux applications comme le Western Blot et l'immunohistochimie. Restez à l'écoute pour les mises à jour.",
          },
          {
            question: "Quel est votre processus de cohérence entre les lots ?",
            answer:
              "Nous assurons une cohérence exceptionnelle entre les lots en produisant nos anticorps par modification génétique des cellules et en établissant des lignées cellulaires stables, éliminant l'utilisation d'animaux dans la production. Nous utilisons également des milieux chimiquement définis sans substrats d'origine animale, garantissant une haute reproductibilité et une qualité constante pour tous les lots.",
          },
          {
            question: "Comment assurez-vous la reproductibilité des résultats avec vos produits ?",
            answer:
              "En employant des méthodes de production cohérentes et en utilisant des milieux chimiquement définis, nous garantissons une haute reproductibilité de nos produits. Nos processus rigoureux de contrôle qualité vous assurent d'obtenir des résultats fiables et cohérents dans votre recherche.",
          },
          {
            question:
              "Que dois-je faire si j'ai besoin d'aide pour l'intégration du produit dans mon flux de travail ?",
            answer:
              "Veuillez contacter notre équipe de support technique pour une assistance personnalisée. Nos experts sont prêts à vous aider à intégrer nos produits dans votre flux de travail et à répondre à toutes vos questions ou préoccupations.",
          },
          {
            question: "Puis-je programmer une démonstration de produit ou une consultation ?",
            answer:
              "Oui, nous serions ravis d'organiser une démonstration de produit ou une consultation avec vous. Nous pouvons programmer des appels vidéo pour discuter en détail de nos produits et répondre à toutes vos questions. Veuillez nous envoyer un email à general_inquires@genotic.com pour fixer un horaire qui vous convient.",
          },
        ],
      },
      ordersAndShipping: {
        title: "Commandes et Expédition",
        icon: "akar-icons:basket",
        items: [
          {
            question:
              "Quelles sont vos options d'expédition et combien de temps prend la livraison ?",
            answer:
              "Nous proposons diverses options d'expédition pour répondre à vos besoins. Les délais de livraison dépendent du produit spécifique et de votre localisation. Des estimations précises de livraison sont fournies sur la page de chaque produit, avec des mises à jour en temps réel du stock pour assurer une disponibilité précise.",
          },
          {
            question: "Expédiez-vous à l'international et y a-t-il des restrictions ?",
            answer:
              "Oui, nous expédions à l'international. Pour passer une commande internationale, veuillez nous contacter pour un devis personnalisé et pour discuter des restrictions potentielles basées sur les réglementations d'importation de votre pays. Nous nous engageons à faciliter l'accès mondial à nos produits tout en respectant toutes les lois et normes applicables.",
          },
          {
            question: "Comment puis-je suivre ma commande après son expédition ?",
            answer:
              "Une fois votre commande expédiée, nous vous enverrons un numéro de suivi par email. Vous pouvez utiliser ce numéro pour suivre la progression de votre envoi via le système de suivi du transporteur. Si vous avez besoin d'aide pour suivre votre commande, n'hésitez pas à nous contacter.",
          },
          {
            question: "Quels modes de paiement acceptez-vous ?",
            answer: "Actuellement, le seul mode de paiement disponible est le virement bancaire.",
          },
          {
            question:
              "Comment puis-je demander un devis pour des commandes en gros ou personnalisées ?",
            answer:
              "Pour les commandes en gros, veuillez nous contacter par email à contact@genotic.com",
          },
          {
            question:
              "Proposez-vous des réductions pour les institutions académiques, les organisations à but non lucratif ou les achats en gros ?",
            answer:
              "Oui, nous proposons des réductions pour les commandes en gros, les institutions académiques et les organisations à but non lucratif. Nous pouvons fournir des coupons qui accordent des réductions. Veuillez nous contacter pour discuter de la façon dont nous pouvons répondre à vos besoins.",
          },
          {
            question: "Puis-je ouvrir un compte de crédit avec votre entreprise ?",
            answer:
              "Oui, nous proposons des comptes de crédit aux entreprises avec lesquelles nous avons établi une coopération. Une fois que nous voyons que tout fonctionne bien, nous pouvons organiser des conditions de paiement post-commande. Veuillez nous contacter pour discuter du processus de demande.",
          },
          {
            question:
              "Comment puis-je mettre à jour mes informations de compte ou gérer mes commandes en ligne ?",
            answer:
              "Vous pouvez mettre à jour vos informations de compte et gérer vos commandes en vous connectant à votre compte sur notre site web. Tout est accessible via le panneau utilisateur, où vous pouvez modifier vos informations personnelles, consulter l'historique de vos commandes et suivre les commandes en cours.",
          },
        ],
      },
      policiesAndSupport: {
        title: "Politiques et Support",
        icon: "akar-icons:arrow-cycle",
        items: [
          {
            question: "Quelle est votre politique de retour et de remboursement ?",
            answer:
              "Nous offrons une politique de retour de 30 jours sur tous nos produits. Si vous n'êtes pas entièrement satisfait—que le produit soit inutilisé ou n'ait pas fonctionné comme prévu—nous fournissons un remboursement complet sans poser de questions. Nous sommes confiants dans la qualité de nos produits et les soutenons pleinement. Votre satisfaction est notre priorité absolue.",
          },
          {
            question: "Quelle est votre politique de garantie pour les produits ?",
            answer:
              "Nous garantissons la qualité et la fiabilité de nos produits. Chaque produit est accompagné d'informations détaillées et de documentation de recherche décrivant les tests spécifiques et les contrôles de qualité effectués. Nos produits sont cohérents entre les lots car nous utilisons des méthodes de production sans animaux. Si vous rencontrez des problèmes, nous offrons un remboursement complet ou un remplacement. Notre politique de garantie reflète notre engagement à fournir des produits fiables et de haute qualité qui répondent à vos besoins de recherche.",
          },
          {
            question:
              "Êtes-vous impliqués dans des initiatives de durabilité ou d'approvisionnement éthique ?",
            answer:
              "Bien que nous soyons une petite entreprise et ne participions pas encore officiellement à des initiatives de durabilité, nous nous engageons dans des pratiques responsables. Nous mettons en œuvre des solutions d'énergie renouvelable, visant à fonctionner hors réseau. De plus, nous n'utilisons pas d'animaux dans notre conception d'anticorps.",
          },
          {
            question:
              "Qui dois-je contacter pour le support client général ou l'assistance technique ?",
            answer:
              "Pour le support client général ou l'assistance technique, veuillez consulter ci-dessous les informations de contact pertinentes.",
          },
          {
            question:
              "Comment puis-je mettre à jour mes informations de compte ou gérer mes commandes en ligne ?",
            answer:
              "Vous pouvez mettre à jour vos informations de compte et gérer vos commandes en vous connectant à votre compte sur notre site web. Tout est accessible via le panneau utilisateur, où vous pouvez modifier vos informations personnelles, consulter l'historique de vos commandes et suivre les commandes en cours.",
          },
        ],
      },
    },
  },
} satisfies SupportBase;
