import type { SupportBase } from "../types";

export default {
  title: "Wsparcie",
  subtitle: "Jak możemy Ci pomóc?",
  description: "Znajdź odpowiedzi na często zadawane pytania i uzyskaj potrzebne wsparcie.",
  productDocumentation: {
    title: "Dokumentacja produktu",
    description:
      "Oferujemy kompleksowe wsparcie techniczne dla wszystkich naszych produktów. Nasz wykwalifikowany zespół jest gotowy pomóc w przypadku wszelkich pytań lub problemów. Aby uzyskać pomoc dotyczącą szczegółów produktu, prosimy zapoznać się z naszą Bazą Wiedzy lub skontaktować się bezpośrednio z naszym zespołem wsparcia technicznego.",
    contact: {
      0: {
        title: "Skontaktuj się z Wsparciem Technicznym",
        email: "technical_support@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Wsparcie w zakresie zamawiania i płatności",
    description:
      "Staramy się, aby proces zamawiania był płynny i prosty. Niezależnie od tego, czy kupujesz pojedynczy przedmiot, czy składasz duże zamówienie, oferujemy różne opcje dostosowane do Twoich potrzeb.",
    contact: {
      0: {
        title: "Skontaktuj się z Wsparciem Technicznym",
        email: "technical_support@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Wysyłka i dostawa",
    description:
      "Nasze produkty są wysyłane na całym świecie, z naciskiem na szybką i niezawodną dostawę. Współpracujemy z zaufanymi dostawcami usług wysyłkowych, aby zapewnić, że Twoje zamówienie dotrze w optymalnym stanie.",
    tableItems: {
      1: {
        key: "Opcje wysyłki",
        value: "Do potwierdzenia",
      },
      2: {
        key: "Śledzenie zamówienia",
        value: "Śledź swoją przesyłkę w czasie rzeczywistym po jej wysłaniu.",
      },
      3: {
        key: "Wysyłka międzynarodowa",
        value: "Do potwierdzenia",
      },
    },
    contact: {
      0: {
        title: "Skontaktuj się z Wsparciem Technicznym",
        email: "technical_support@genotic.com",
      },
    },
  },
  contact: {
    title: "Skontaktuj się z nami",
    subtitle: "Nie znalazłeś odpowiedzi na swoje pytanie? Skontaktuj się z nami.",
    email: "contact@genotic.com",
  },
  faq: {
    sections: {
      storageAndHandling: {
        title: "Przechowywanie i Obsługa",
        icon: "akar-icons:headphone",
        items: [
          {
            question: "Jakie są zalecane warunki przechowywania dla Waszych produktów?",
            answer:
              "Wszystkie produkty liofilizowane powinny być przechowywane w temperaturze pokojowej. W przypadku produktów w formie płynnej zalecane jest przechowywanie w temperaturze 4°C lub w zamrażarce. Prosimy zapoznać się ze szczegółową kartą produktu, aby uzyskać konkretne instrukcje przechowywania dla każdego produktu.",
          },
          {
            question: "Jak powinienem obchodzić się z produktami wrażliwymi na temperaturę?",
            answer:
              "Prosimy przestrzegać konkretnych instrukcji przechowywania podanych w karcie produktu. Ogólnie produkty płynne powinny być przechowywane w temperaturze 4°C lub w zamrażarce, aby zachować ich stabilność. Właściwe przechowywanie zapewnia skuteczność i trwałość produktu.",
          },
          {
            question: "Jaki jest okres trwałości Waszych produktów i jak jest on określany?",
            answer:
              "Okres trwałości naszych produktów zależy od metody produkcji i warunków przechowywania. W przypadku produktów liofilizowanych okres trwałości wynosi około 12 miesięcy przy przechowywaniu w chłodnym, ciemnym miejscu. Dla produktów mrożonych zostaną dostarczone szczegółowe informacje o okresie trwałości. Ponieważ nasze białka są nowo opracowane, prowadzimy bieżące badania stabilności, aby dostarczyć precyzyjne dane dotyczące okresu trwałości.",
          },
          {
            question:
              "Jak mogę uzyskać Certyfikaty Analizy (COA) lub Karty Charakterystyki (SDS) dla Waszych produktów?",
            answer:
              "Wszystkie niezbędne dokumenty, w tym Karta Charakterystyki, Karta Techniczna i Certyfikat Analizy, są bezpośrednio dostępne na stronie każdego produktu. Dokumenty te można przeglądać i pobierać dla wygody.",
          },
          {
            question:
              "Czy mogę uzyskać dostęp do protokołów, przewodników użytkowania lub dokumentacji technicznej dla Waszych produktów?",
            answer:
              "Obecnie nie oferujemy protokołów ani przewodników użytkownika, ale planujemy je dodać w przyszłości. Jednakże dostarczamy dokumentację techniczną i zbiór danych zagregowanych z publikacji naukowych, które można znaleźć na odpowiednich stronach produktów. Jesteśmy zaangażowani we wspieranie Twoich potrzeb badawczych i będziemy stale aktualizować nasze zasoby.",
          },
          {
            question:
              "Jakie środki kontroli jakości są stosowane w celu zapewnienia spójności produktów?",
            answer:
              "Wdrażamy rygorystyczne środki kontroli jakości dla każdej partii produktów. Obejmuje to elektroforezę żelową do oceny czystości i masy cząsteczkowej, interferometrię bio-warstwową (BLI) do pomiaru interakcji białek oraz analizę rezonansu plazmonów powierzchniowych (SPR) dla określonych białek. W przypadku enzymów przeprowadzamy specyficzne testy aktywności enzymatycznej, jak opisano w karcie charakterystyki produktu. Dodatkowo przeprowadzamy analizy SDS-PAGE i dla niektórych białek analizy Western Blot, aby zapewnić najwyższą jakość.",
          },
        ],
      },
      performance: {
        title: "Wydajność i Wsparcie",
        icon: "akar-icons:headphone",
        items: [
          {
            question: "Co powinienem zrobić, jeśli produkt nie działa zgodnie z oczekiwaniami?",
            answer:
              "Prosimy o kontakt z naszym zespołem wsparcia technicznego. Jesteśmy tutaj, aby pomóc w rozwiązaniu wszelkich problemów i zapewnić optymalną wydajność naszych produktów.",
          },
          {
            question: "Czy oferujecie niestandardowe formulacje lub modyfikacje Waszych produktów?",
            answer:
              'Tak, oferujemy niestandardowe formulacje i modyfikacje naszych produktów. Wszystkie istotne informacje można znaleźć w sekcji "Usługi" na naszej stronie internetowej. Aby uzyskać dodatkowe informacje, prosimy o kontakt mailowy na adres contact@genotic.com',
          },
          {
            question:
              "Czy Wasze produkty są zwalidowane do konkretnych zastosowań badawczych (np. immunotesty, diagnostyka)?",
            answer:
              "Obecnie jesteśmy w trakcie walidacji naszych produktów do konkretnych zastosowań. W najbliższej przyszłości dostarczymy szczegółowe informacje wskazujące, które produkty są odpowiednie do zastosowań takich jak Western Blot i immunohistochemia. Prosimy śledzić aktualizacje.",
          },
          {
            question: "Jaki jest Wasz proces zapewnienia spójności między partiami?",
            answer:
              "Zapewniamy wyjątkową spójność między partiami poprzez produkcję naszych przeciwciał za pomocą modyfikacji genetycznej komórek i ustanowienie stabilnych linii komórkowych, eliminując wykorzystanie zwierząt w produkcji. Używamy również chemicznie zdefiniowanych mediów bez substratów pochodzenia zwierzęcego, gwarantując wysoką powtarzalność i spójną jakość we wszystkich partiach.",
          },
          {
            question: "Jak zapewniacie powtarzalność wyników przy użyciu Waszych produktów?",
            answer:
              "Poprzez stosowanie spójnych metod produkcji i wykorzystanie chemicznie zdefiniowanych mediów, gwarantujemy wysoką powtarzalność naszych produktów. Nasze rygorystyczne procesy kontroli jakości zapewniają, że możesz uzyskać wiarygodne i spójne wyniki w swoich badaniach.",
          },
          {
            question:
              "Co powinienem zrobić, jeśli potrzebuję pomocy w integracji produktu w moim przepływie pracy?",
            answer:
              "Prosimy o kontakt z naszym zespołem wsparcia technicznego w celu uzyskania spersonalizowanej pomocy. Nasi eksperci są gotowi pomóc w integracji naszych produktów w Twoim przepływie pracy i odpowiedzieć na wszelkie pytania lub wątpliwości.",
          },
          {
            question: "Czy mogę umówić się na demonstrację produktu lub konsultację?",
            answer:
              "Tak, z przyjemnością zorganizujemy demonstrację produktu lub konsultację. Możemy zaplanować rozmowy wideo, aby szczegółowo omówić nasze produkty i odpowiedzieć na wszelkie pytania. Prosimy o kontakt mailowy na adres general_inquires@genotic.com, aby ustalić dogodny termin.",
          },
        ],
      },
      ordersAndShipping: {
        title: "Zamówienia i Wysyłka",
        icon: "akar-icons:basket",
        items: [
          {
            question: "Jakie są opcje wysyłki i jak długo trwa dostawa?",
            answer:
              "Oferujemy różne opcje wysyłki, aby sprostać Twoim potrzebom. Czasy dostawy zależą od konkretnego produktu i Twojej lokalizacji. Dokładne szacunki czasu dostawy są podane na stronie każdego produktu, wraz z aktualizacjami stanu magazynowego w czasie rzeczywistym, aby zapewnić dokładną dostępność.",
          },
          {
            question: "Czy wysyłacie międzynarodowo i czy są jakieś ograniczenia?",
            answer:
              "Tak, wysyłamy międzynarodowo. Aby złożyć zamówienie międzynarodowe, prosimy o kontakt w celu otrzymania spersonalizowanej wyceny i omówienia potencjalnych ograniczeń wynikających z przepisów importowych Twojego kraju. Jesteśmy zaangażowani w ułatwianie globalnego dostępu do naszych produktów, przestrzegając jednocześnie wszystkich obowiązujących przepisów i standardów.",
          },
          {
            question: "Jak mogę śledzić moje zamówienie po wysyłce?",
            answer:
              "Po wysłaniu zamówienia wyślemy Ci numer śledzenia e-mailem. Możesz użyć tego numeru do monitorowania postępu przesyłki przez system śledzenia przewoźnika. Jeśli potrzebujesz pomocy w śledzeniu zamówienia, nie wahaj się z nami skontaktować.",
          },
          {
            question: "Jakie metody płatności akceptujecie?",
            answer: "Obecnie jedyną dostępną metodą płatności jest przelew bankowy.",
          },
          {
            question: "Jak mogę poprosić o wycenę dla zamówień hurtowych lub niestandardowych?",
            answer:
              "W przypadku zamówień hurtowych prosimy o kontakt mailowy na adres contact@genotic.com",
          },
          {
            question:
              "Czy oferujecie zniżki dla instytucji akademickich, organizacji non-profit lub zakupów hurtowych?",
            answer:
              "Tak, oferujemy zniżki na zamówienia hurtowe, dla instytucji akademickich i organizacji non-profit. Możemy zapewnić kupony przyznające zniżki. Prosimy o kontakt, aby omówić, jak możemy dostosować się do Twoich potrzeb.",
          },
          {
            question: "Czy mogę otworzyć konto kredytowe w Waszej firmie?",
            answer:
              "Tak, oferujemy konta kredytowe firmom, z którymi mamy nawiązaną współpracę. Gdy zobaczymy, że wszystko działa dobrze, możemy ustalić warunki płatności po zamówieniu. Prosimy o kontakt, aby omówić proces aplikacji.",
          },
          {
            question:
              "Jak mogę zaktualizować informacje o moim koncie lub zarządzać zamówieniami online?",
            answer:
              "Możesz zaktualizować informacje o swoim koncie i zarządzać zamówieniami, logując się na swoje konto na naszej stronie internetowej. Wszystko jest dostępne przez panel użytkownika, gdzie możesz edytować dane osobowe, przeglądać historię zamówień i śledzić bieżące zamówienia.",
          },
        ],
      },
      policiesAndSupport: {
        title: "Polityki i Wsparcie",
        icon: "akar-icons:arrow-cycle",
        items: [
          {
            question: "Jaka jest Wasza polityka zwrotów i refundacji?",
            answer:
              "Oferujemy 30-dniową politykę zwrotów na wszystkie nasze produkty. Jeśli nie jesteś w pełni zadowolony—czy to produkt jest nieużywany, czy nie działał zgodnie z oczekiwaniami—zapewniamy pełny zwrot kosztów bez zadawania pytań. Jesteśmy pewni jakości naszych produktów i w pełni za nimi stoimy. Twoja satysfakcja jest naszym najwyższym priorytetem.",
          },
          {
            question: "Jaka jest Wasza polityka gwarancyjna dla produktów?",
            answer:
              "Stoimy za jakością i niezawodnością naszych produktów. Każdy produkt jest dostarczany ze szczegółowymi informacjami i dokumentacją badawczą opisującą przeprowadzone testy i kontrole jakości. Nasze produkty są spójne między partiami, ponieważ wykorzystujemy metody produkcji bez użycia zwierząt. W przypadku problemów oferujemy pełny zwrot kosztów lub wymianę. Nasza polityka gwarancyjna odzwierciedla nasze zaangażowanie w dostarczanie niezawodnych, wysokiej jakości produktów, które spełniają Twoje potrzeby badawcze.",
          },
          {
            question:
              "Czy jesteście zaangażowani w inicjatywy zrównoważonego rozwoju lub etycznego zaopatrzenia?",
            answer:
              "Chociaż jesteśmy małą firmą i nie uczestniczymy jeszcze oficjalnie w inicjatywach zrównoważonego rozwoju, jesteśmy zaangażowani w odpowiedzialne praktyki. Wdrażamy rozwiązania energii odnawialnej, dążąc do działania poza siecią. Dodatkowo nie używamy zwierząt w naszym projektowaniu przeciwciał.",
          },
          {
            question:
              "Z kim powinienem się skontaktować w sprawie ogólnego wsparcia klienta lub pomocy technicznej?",
            answer:
              "W sprawie ogólnego wsparcia klienta lub pomocy technicznej prosimy zapoznać się z odpowiednimi informacjami kontaktowymi poniżej.",
          },
          {
            question:
              "Jak mogę zaktualizować informacje o moim koncie lub zarządzać zamówieniami online?",
            answer:
              "Możesz zaktualizować informacje o swoim koncie i zarządzać zamówieniami, logując się na swoje konto na naszej stronie internetowej. Wszystko jest dostępne przez panel użytkownika, gdzie możesz edytować dane osobowe, przeglądać historię zamówień i śledzić bieżące zamówienia.",
          },
        ],
      },
    },
  },
} satisfies SupportBase;
