import type { I18nLocale } from "../en";
import countries from "./countries";
import error from "./error";
import saleor from "./saleor";
import support from "./support";
import zodI18n from "./zod";

export default {
  saleor,
  error,
  support,
  countries,
  zodI18n,
  title: "Genotic",
  common: {
    cancel: "Cancelar",
    close: "Cerrar",
    text: "Texto",
    showMore: "Mostrar más",
    showLess: "Mostrar menos",
    edit: "Editar",
    save: "Guardar",
    add: "Agregar",
    day: "Día | Días",
    show: "Mostrar",
    hide: "Ocultar",
    download: "Descargar | Descargas",
    search: "Buscar...",
    scrollToTop: "Ir arriba",
    delete: "Eliminar",
    or: "o",
    submit: "Enviar",
    submitting: "Enviando",
    open: "Ouvrir",
  },
  unit: {
    pc: "ud",
  },
  footer: {
    termsAndConditions: "Términos y condiciones",
    privacyPolicy: "Política de privacidad",
    new: "Nuevo",
    hiring: "Estamos contratando!",
  },
  header: {
    all: "Todo",
    myAccount: "Mi cuenta",
    cart: "Carrito",
    signOut: "Cerrar sesión",
    languageDisplay: "Español (EUR)",
    countryWithCurrency: "Brasil (EUR)",
    menu: "Menú",
    search: "Buscar",
  },
  search: {
    searchResultsInIndividualCategories: "Resultados de búsqueda en categorías individuales",
    closeSearchResults: "Cerrar resultados de búsqueda",
    resultsInIndividualCategories: "Resultados en categorías individuales",
  },
  profile: {
    title: "Perfil",
    info: "Información",
    addresses: "Direcciones",
    giftCards: "Tarjetas de regalo",
    orders: {
      title: "Pedidos",
      empty: "No has creado ningún pedido",
    },
    editProfile: "Editar perfil",
  },
  address: {
    title: "Dirección",
    shippingAddress: "Dirección de envío | Direcciones de envío",
    billingAddress: "Dirección de facturación | Direcciones de facturación",
    defaultAddress: "Dirección por defecto",
    thisAddressIsUsedForBothShippingAndBilling:
      "Esta dirección se utiliza para envíos y facturaciones",
    defaultForShippingAndBilling: "Por defecto para envíos y facturaciones",
    defaultShippingAddress: "Dirección de envío por defecto",
    defaultBillingAddress: "Dirección de facturación por defecto",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Línea de dirección 1",
    streetAddress2: "Línea de dirección 2",
    postalCode: "Código postal",
    city: "Ciudad",
    cityArea: "Área de la ciudad",
    email: "@:auth.email",
    vat: "VAT",
    country: "País",
    countryArea: "Estado / Provincia",
    selectACountry: "Selecciona un país",
    companyName: "Nombre de la empresa/institución",
    phoneNumber: "Número de teléfono",
    billingMatchesShippingAddress: "La dirección de facturación coincide con la dirección de envío",
    editAddress: "Editar dirección",
    deleteAddress: "Eliminar dirección",
    addAddress: "Agregar dirección",
    noAddress: "No hay dirección",
    noDefaultAddress: "No hay dirección por defecto",
    empty: "No has agregado ninguna dirección",
    organizationInfo: " Información de la organización",
  },
  order: {
    cart: {
      empty: "Tu carrito está vacío",
      title: "Carrito",
      adjustItemQuantity: "Ajustar la cantidad del artículo",
      shippingFrom: "Envío desde {amount}",
    },
    checkout: {
      title: "Pedido",
      addAnythingToCartToContinue: "Añade algo a tu carrito para continuar",
      goBackToStore: "Volver a la tienda",
      delivery: "Envío",
      completeCheckout: "Completar pedido",
      haveAPromoCode: "¿Tienes un código promocional?",
      promoCode: "Código promocional",
      specifyHowYouWantToPlaceYourOrder: "Especifica cómo quieres colocar tu pedido",
      continueAsGuest: "Continuar como invitado",
      continueAsGuestDescription:
        "En cada momento del proceso de pedido puedes crear un perfil de usuario gratuito",
      iHaveAnAccount: "Ya tengo una cuenta",
      createAccount: "Crear cuenta",
      continueAsMember: "Continuar como miembro",
    },
    steps: {
      address: "Dirección",
      shipping: "Envío",
      payment: "Pago",
      continue: "Continuar",
      back: "Atrás",
    },
    paymentMethods: {
      title: "Métodos de pago",
      bankTransfer: "Transferencia bancaria",
    },
    number: "Número de pedido",
    status: "Estado",
    paid: "Pagado",
    unpaid: "Pendiente",
    paymentStatus: "Estado de pago",
    complete: "Completado",
    quantity: "Cantidad",
    info: "Información de pedido",
    arrives: "llega",
    addToCart: "Añadir al carrito",
    addedToCart: "El producto {product} ha sido añadido a tu carrito",
    goToCart: "Ir al carrito",
    goToCheckout: "Ir a la caja",
    shippingTo: "Envío a",
    total: "Total",
    subtotal: "Subtotal",
    shipping: {
      title: "envío",
      country: "España",
      noShippingMethodsAvailable: "No hay métodos de envío disponibles",
      shippingMethods: "Métodos de envío",
    },
    details: {
      title: "Detalles del pedido",
      qty: "Cant.",
    },
  },
  auth: {
    login: "Iniciar sesión",
    register: "Registrarse",
    logout: "Cerrar sesión",
    email: "Correo electrónico",
    password: "Contraseña",
    password2: "Confirmar contraseña",
    firstName: "Nombre",
    lastName: "Apellido",
    alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
    dontHaveAnAccount: "¿No tienes una cuenta?",
    forgotPassword: "¿Olvidaste tu contraseña?",
    createNewAccount: "Crear nueva cuenta",
  },
  product: {
    title: "Producto | Productos",
    addToCart: "Añadir al carrito",
    addedToCart: {
      toastTitle: "El producto {product} ha sido añadido a tu carrito",
      toastDescription: "Ahora puedes verlo ahí...",
    },
    outOfStock: "Agotado",
    synonyms: "Sinónimos",
    noProductsFound: "No se han encontrado productos",
    description: {
      recommendedDilution: "Dilución recomendada",
      applications: "Aplicación | Aplicaciones",
      dilution: "Dilución",
      testedApplications: "Aplicaciones probadas",
      targetSpecies: "Especies objetivo",
      targetAliases: "Alias objetivo",
      species: "Especies",
      uniProtIdAndGenId: "ID UniProt y ID Genético",
      images: "Imagen | Imágenes",
      publications: "Publicación | Publicaciones",
      authors: "Autor | Autores",
      performanceData: "Datos de rendimiento",
      support: "Soporte",
      productSpecificProtocols: "Protocolos específicos del producto",
      frequentlyAskedQuestions: "Preguntas frecuentes",
      contact: "Contacto",
      productDetails: "Detalles del producto",
      targetInfo: "Información del objetivo",
      relatedProducts: "Producto relacionado | Productos relacionados",
      files: "archivos",
    },
  },
  ui: {
    availability: {
      available: "Disponible",
      unavailable: "No disponible",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Categoría | Categorías",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Categorías",
    noCategoriesFound: "No se han encontrado categorías",
  },
  warning: {
    thisActionCannotBeUndone: "Esta acción no se puede deshacer",
  },
  notFound: {
    title: "This page is gone!",
    description:
      "At Genotic, we are committed to providing the support and resources you need to make the most of our products. Our support team is here to ensure you have access to the tools, information, and guidance required to achieve successful results.",
    button: "Go back",
  },
  lab: {
    title: "Laboratorio",
    hero: {
      title: "Producción de anticuerpos y control de calidad en nuestro laboratorio",
      description:
        "Creemos en la transparencia de nuestros métodos de producción. Esta visión general describe nuestra línea de producción, desde la clonación inicial hasta la purificación y el control de calidad, mostrando cómo aseguramos la consistencia en cada lote.",
    },
    process: {
      steps: {
        1: {
          title: "Clonación",
          description:
            "Nuestros algoritmos de IA diseñan regiones variables de anticuerpos específicamente adaptadas para unirse a antígenos seleccionados con alta especificidad. Estas regiones variables se clonan luego en un vector seleccionado.",
          list: [],
          notes: [
            "En esta etapa, podemos personalizar la región constante del anticuerpo (por ejemplo, cadena pesada humana, murina o de conejo) según las especificaciones deseadas del producto.",
            "Las regiones variables diseñadas pueden fusionarse con cualquier cadena pesada elegida. Los vectores resultantes se amplifican en bacterias E. coli para asegurar altos rendimientos para procesos posteriores.",
          ],
        },
        2: {
          title: "Producción",
          description:
            "El siguiente paso implica la transfección de células bacterianas o la transformación de células de mamíferos para permitir la producción del anticuerpo deseado.",
          list: [],
          notes: [
            "Al optimizar las condiciones de cultivo y los protocolos de estimulación, podemos aumentar significativamente la eficiencia de producción. Esta etapa asegura la escalabilidad y reproducibilidad en la generación de anticuerpos de alta calidad.",
          ],
        },
        3: {
          title: "Purificación",
          description:
            "La purificación de anticuerpos se realiza mediante técnicas de cromatografía líquida rápida de proteínas (FPLC). Cada anticuerpo se somete a un riguroso proceso de purificación de dos o tres pasos, que típicamente incluye:",
          list: [
            "Cromatografía de afinidad por metal inmovilizado (IMAC)",
            "Cromatografía de intercambio iónico (IEX)",
            "Cromatografía de exclusión por tamaño (SEC)",
          ],
          notes: [
            "Este enfoque de múltiples etapas garantiza la eliminación de impurezas y asegura la producción de anticuerpos de la más alta pureza.",
          ],
        },
        4: {
          title: "Control de Calidad",
          description:
            "Cada anticuerpo se somete a pruebas exhaustivas de control de calidad para asegurar la consistencia y el rendimiento:",
          list: [
            {
              term: "Espectrometría de masas:",
              definition: "Se utiliza para confirmar la secuencia de aminoácidos del anticuerpo.",
            },
            {
              term: "SDS-PAGE y Western Blot (WB):",
              definition: "Estas técnicas verifican el peso molecular del anticuerpo.",
            },
            {
              term: "Cinética de unión:",
              definition:
                "Se analiza la interacción entre el anticuerpo y su antígeno objetivo, y se determina la constante de disociación (K𝐷) mediante interferometría de bicapa (BLI).",
            },
          ],
          notes: [
            "Este riguroso control de calidad asegura la fiabilidad funcional de nuestros productos.",
          ],
        },
        5: {
          title: "Conjugación",
          description:
            "Para aplicaciones específicas, los anticuerpos seleccionados se conjugan con fluoróforos o enzimas, mejorando su utilidad en técnicas de detección y análisis.",
          list: [],
          notes: [],
        },
        6: {
          title: "Almacenamiento",
          description:
            "Los anticuerpos finalizados generalmente se liofilizan y almacenan congelados para mantener su estabilidad y funcionalidad durante períodos prolongados.",
          list: [],
          notes: [],
        },
      },
    },
    excellence: {
      title: "Compromiso con la Excelencia",
      heading:
        "Cada etapa de la producción de anticuerpos en nuestro laboratorio se realiza bajo estricta adherencia a protocolos establecidos.",
      description:
        "Esto asegura alta reproducibilidad y calidad excepcional en todos nuestros productos, cumpliendo con las demandas de la investigación y aplicaciones clínicas.",
    },
  },
  services: {
    title: "Servicios",
    hero: {
      title: "Diseño al instante",
      description: "¿Necesitas un anticuerpo que no puedes encontrar?",
      subDescription:
        "Diseñamos anticuerpos personalizados in-silico, entregando un producto listo para usar en 20 días.",
    },
    process: {
      steps: {
        1: {
          title: "Envía tu propia estructura",
          description:
            "Todo lo que necesitamos es un archivo .pdb con la estructura 3D a la que quieres que se una el anticuerpo.",
        },
        2: {
          title: "Procedimiento rentable",
          description:
            "Después de evaluar la capacidad de crear el producto personalizado, te pediremos que pagues 3500 €. ¡Entonces podemos empezar a producir!",
        },
        3: {
          title: "Diseño por IA",
          description:
            "Usando 200 GPUs y nuestros algoritmos de IA, calcularemos los mejores candidatos para tu objetivo.",
        },
        4: {
          title: "Del in-silico al laboratorio",
          description:
            "Produciremos cuidadosamente los candidatos más prometedores en nuestro propio laboratorio.",
        },
        5: {
          title: "Anticuerpos específicos y precisos",
          description:
            "Para garantizar que nuestros productos sean de la mejor calidad, nuestros productos son probados y tienen una pureza mínima del 90% determinada por resultados SDS-PAGE. Además, realizamos espectrometría de masas, aseguramos que la fase reversa analítica tenga un pico único y agudo y que las endotoxinas estén por debajo del nivel de detección.",
        },
        6: {
          title: "Garantía sin riesgos",
          description:
            "Para garantizar que nuestros productos sean de la mejor calidad, nuestros productos son probados y tienen una pureza mínima del 90% determinada por resultados SDS-PAGE. Además, realizamos espectrometría de masas, aseguramos que la fase reversa analítica tenga un pico único y agudo y que las endotoxinas estén por debajo del nivel de detección.",
        },
      },
      confirmation: "En 48 horas confirmaremos si podemos crear el anticuerpo",
      delivery: "Después de un máximo de 3 semanas, recibirás el anticuerpo",
    },
    contact: {
      title: "Contáctanos",
      requestTitle: "Solicitar anticuerpo personalizado",
      description:
        "Cuéntanos sobre tu proyecto. Te proporcionaremos instrucciones detalladas para la presentación.",
      email: "contact@genotic.com",
    },
    communication: {
      title: "Comunicación transparente",
      description:
        "Durante el proceso de producción de tres semanas de tus anticuerpos personalizados, proporcionamos actualizaciones regulares sobre cada etapa de fabricación, desde el diseño hasta la purificación final. Nuestro sistema de notificación automatizado te mantiene informado sobre los hitos clave de la producción y los resultados del control de calidad.",
    },
    faq: {
      title: "Preguntas frecuentes",
      items: [
        {
          question: "¿Qué documentación se proporciona con el producto final?",
          answer:
            "Cada anticuerpo viene con un paquete de documentación completo que incluye análisis de pureza (SDS-PAGE), resultados de espectrometría de masas y datos de cromatografía de fase reversa analítica. También proporcionamos información detallada sobre las condiciones de almacenamiento y recomendaciones de manipulación.",
        },
        {
          question: "¿Cuál es la tasa de éxito de su diseño de anticuerpos personalizados?",
          answer:
            "Nuestro enfoque basado en IA tiene una tasa de éxito de más del 90% en la creación de anticuerpos que cumplen o superan los requisitos de unión especificados. Si determinamos que no podemos crear un anticuerpo que satisfaga tus necesidades, te informaremos dentro de las 48 horas y proporcionaremos un reembolso completo.",
        },
        {
          question: "¿Pueden diseñar anticuerpos para objetivos complejos o desafiantes?",
          answer:
            "Sí, nuestros algoritmos de IA son particularmente efectivos con objetivos complejos. Hemos diseñado con éxito anticuerpos para objetivos tradicionalmente difíciles, incluyendo proteínas de membrana, epítopos conformacionales y variantes de proteínas altamente similares.",
        },
        {
          question: "¿Qué sucede si el anticuerpo no cumple con las especificaciones?",
          answer:
            "Ofrecemos una garantía de satisfacción del 100%. Si el anticuerpo no cumple con las especificaciones acordadas, rediseñaremos y produciremos un nuevo anticuerpo sin costo adicional o proporcionaremos un reembolso completo.",
        },
      ],
    },
  },
  about: {
    title: "Sobre nosotros",
    intro:
      "Somos Genotic - un equipo de científicos e ingenieros que trabajan en la intersección de la IA y el diseño de anticuerpos. Nuestra misión es expandir las posibilidades en la investigación de ciencias de la vida combinando una profunda experiencia científica con innovación computacional.",
    values: [
      {
        name: "Ciencia.",
        description:
          "Diseñamos anticuerpos personalizados para cualquier objetivo, incluidos aquellos ignorados por los productores tradicionales. Nuestro objetivo es permitir la investigación en dominios inexplorados.",
      },
      {
        name: "Innovación.",
        description:
          "Hemos reemplazado meses de trabajo de prueba y error en el laboratorio con precisión computacional. Nuestra IA diseña anticuerpos listos para usar en semanas, no en años.",
      },
      {
        name: "Progreso.",
        description:
          "Crecemos a través de resultados validados, no promesas. Cada implementación validada nos guía hacia nuevas posibilidades.",
      },
    ],
    mission: {
      title: "Nuestra Misión",
      paragraph1:
        "Nuestro objetivo es utilizar el potencial revolucionario de la IA, especialmente la sofisticación de los grandes modelos de lenguaje y el dinamismo de las redes neuronales convolucionales de grafos, para trazar el curso de nuestro futuro compartido.",
      paragraph2:
        "Estamos convencidos de la extraordinaria capacidad de la IA para diseñar nuevos anticuerpos, acelerar el ritmo del descubrimiento científico y combatir enfermedades potencialmente mortales. En estas inmensas capacidades, vemos la promesa de curación y esperanza para toda la humanidad.",
    },
    beyondBusiness: {
      title: "Más Allá del Negocio",
      commercial: {
        title: "El éxito comercial impulsando el progreso científico.",
        description:
          "Creemos que el éxito comercial y el progreso científico pueden reforzarse mutuamente. Nuestro modelo de negocio se basa en el avance científico continuo, permitiéndonos explorar nuevas fronteras en el diseño computacional mientras apoyamos iniciativas de investigación más amplias. Este enfoque nos mantiene centrados en el impacto científico a largo plazo en lugar de las ganancias a corto plazo.",
      },
      science: {
        title: "Un presupuesto limitado no debería limitar la gran ciencia.",
        description:
          "Aunque operamos como una entidad comercial, buscamos activamente asociaciones con científicos que exploran áreas prometedoras pero con fondos insuficientes. Si ves potencial para usar nuestra tecnología en tu investigación, te animamos a contactarnos independientemente de tu situación financiera.",
      },
    },
    contact: {
      title: "Contáctanos",
      subtitle: "Discutir Oportunidades de Colaboración",
    },
    numbers: {
      title: "Genotic en Números",
      items: [
        {
          unit: "",
          description: "Anticuerpos diseñados",
        },
        {
          unit: "días",
          description: "Tiempo de producción de anticuerpos personalizados",
        },
        {
          unit: "años",
          description: "Experiencia en diseño de anticuerpos",
        },
      ],
    },
    guarantee: {
      title: "Nuestra Garantía",
      subtitle:
        "En el corazón de nuestra empresa se encuentra un compromiso inquebrantable con la calidad.",
      description:
        "Garantizamos el rendimiento de cada anticuerpo que producimos porque entendemos que las herramientas confiables son fundamentales para el éxito de la investigación. Esto no es solo un estándar de calidad - es nuestro principio fundamental que guía todo lo que hacemos.",
    },
  },
  home: {
    hero: {
      title: "Revolución de anticuerpos con IA",
      description:
        "Accede a nuestra creciente biblioteca de más de 1.200 anticuerpos diseñados con IA. ¿Necesitas algo específico? Podemos diseñar anticuerpos personalizados para tus objetivos únicos.",
    },
    ourApproach: {
      title: "Nuestro enfoque",
      items: [
        {
          title: "Diseño con IA",
          description:
            "Los algoritmos de IA permiten un diseño más rápido de anticuerpos estándar y soluciones personalizadas para nuevos objetivos.",
        },
        {
          title: "Producción rápida",
          description:
            "Nuestras líneas de diseño y producción nos permiten crear nuevos anticuerpos en semanas, no en años.",
        },
        {
          title: "Diseño sin animales",
          description:
            "Mediante el uso de técnicas computacionales, garantizamos un diseño ético de productos, eliminando los experimentos con animales.",
        },
        {
          title: "Calidad garantizada",
          description:
            "Nuestros productos se someten a rigurosas pruebas utilizando procedimientos como SDS-PAGE, Western Blot o espectrometría de masas.",
        },
      ],
    },
    products: {
      title: "Nuestros productos",
      categories: {
        antibodies: "Anticuerpos",
        proteins: "Proteínas",
        enzymes: "Enzimas",
      },
    },
    testimonials: {
      title: "Testimonios",
      navigation: {
        previous: "Testimonio anterior",
        next: "Siguiente testimonio",
      },
    },
    designOnTheFly: {
      title: "Diseño al instante",
      heading: "Solicitar anticuerpos personalizados",
      description:
        "Accede a nuestra creciente biblioteca de más de 1.200 anticuerpos diseñados con IA. ¿Necesitas algo específico? Podemos diseñar anticuerpos personalizados para tus objetivos únicos.",
      learnMore: "Más información",
    },
  },
} satisfies I18nLocale;
