import type { SupportBase } from "../types";

export default {
  title: "Soporte",
  subtitle: "¿Cómo podemos ayudarte?",
  description: "Encuentra respuestas a preguntas frecuentes y obtén el soporte que necesitas.",
  productDocumentation: {
    title: "Documentación del Producto",
    description:
      "Ofrecemos soporte técnico integral para todos nuestros productos. Nuestro equipo experto está listo para ayudar con cualquier pregunta o solución de problemas. Para obtener ayuda con los detalles del producto, consulta nuestra Base de Conocimientos o contacta directamente con nuestro equipo de soporte técnico.",
    contact: {
      0: {
        title: "Contactar Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Soporte de Pedidos y Pagos",
    description:
      "Nos esforzamos por hacer que el proceso de pedido sea fluido y sencillo. Ya sea que estés comprando un solo artículo o realizando un pedido al por mayor, ofrecemos varias opciones para satisfacer tus necesidades.",
    contact: {
      0: {
        title: "Contactar Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Envío y Entrega",
    description:
      "Nuestros productos se envían a todo el mundo con un enfoque en la entrega rápida y confiable. Colaboramos con proveedores de envío de confianza para asegurar que tu pedido llegue en condiciones óptimas.",
    tableItems: {
      1: {
        key: "Opciones de Envío",
        value: "Por confirmar",
      },
      2: {
        key: "Seguimiento de Pedidos",
        value: "Rastrea tu envío en tiempo real una vez que se haya despachado.",
      },
      3: {
        key: "Envío Internacional",
        value: "Por confirmar",
      },
    },
    contact: {
      0: {
        title: "Contactar Soporte Técnico",
        email: "technical_support@genotic.com",
      },
    },
  },
  contact: {
    title: "Contáctanos",
    subtitle: "¿No encuentras respuesta a tu pregunta? Ponte en contacto con nosotros.",
    email: "contact@genotic.com",
  },
  faq: {
    sections: {
      storageAndHandling: {
        title: "Almacenamiento y Manipulación",
        icon: "akar-icons:headphone",
        items: [
          {
            question:
              "¿Cuáles son las condiciones de almacenamiento recomendadas para sus productos?",
            answer:
              "Todos los productos liofilizados deben almacenarse a temperatura ambiente. Para productos en forma líquida, se recomienda el almacenamiento a 4 °C o en congelador. Por favor, consulta la ficha técnica detallada del producto para obtener instrucciones específicas de almacenamiento para cada producto.",
          },
          {
            question: "¿Cómo debo manipular los productos sensibles a la temperatura?",
            answer:
              "Por favor, sigue las instrucciones específicas de almacenamiento proporcionadas en la ficha del producto. En general, los productos líquidos deben almacenarse a 4 °C o en el congelador para mantener su estabilidad. El almacenamiento adecuado asegura la eficacia y longevidad del producto.",
          },
          {
            question: "¿Cuál es la vida útil de sus productos y cómo se determina?",
            answer:
              "La vida útil de nuestros productos depende del método de producción y las condiciones de almacenamiento. Para productos liofilizados, la vida útil es de aproximadamente 12 meses cuando se almacenan en un lugar fresco y oscuro. Para productos congelados, se proporcionará información específica sobre la vida útil. Como nuestras proteínas son de nuevo desarrollo, estamos realizando estudios de estabilidad continuos para proporcionar datos precisos sobre la vida útil.",
          },
          {
            question:
              "¿Cómo puedo obtener los Certificados de Análisis (COA) o las Hojas de Datos de Seguridad (SDS) para sus productos?",
            answer:
              "Todos los documentos necesarios, incluyendo la Hoja de Datos de Seguridad, Ficha Técnica y Certificado de Análisis, están directamente disponibles en la página de cada producto. Estos documentos pueden ser consultados y descargados para tu conveniencia.",
          },
          {
            question:
              "¿Puedo acceder a protocolos, guías de uso o documentación técnica para sus productos?",
            answer:
              "En este momento, no ofrecemos protocolos o guías de usuario, pero planeamos agregarlos en el futuro. Sin embargo, proporcionamos documentación técnica y una colección de datos agregados de artículos científicos, que se pueden encontrar en las páginas de productos relevantes. Estamos comprometidos a apoyar tus necesidades de investigación y actualizaremos continuamente nuestros recursos.",
          },
          {
            question:
              "¿Qué medidas de control de calidad están implementadas para asegurar la consistencia del producto?",
            answer:
              "Implementamos medidas rigurosas de control de calidad para cada lote de productos. Esto incluye electroforesis en gel para evaluar la pureza y el peso molecular, Interferometría de Bicapa (BLI) para medir las interacciones proteicas, y análisis de Resonancia de Plasmones Superficiales (SPR) para ciertas proteínas. Para enzimas, realizamos ensayos de actividad enzimática específicos para cada enzima, como se detalla en la hoja de datos de seguridad del producto. Además, realizamos análisis SDS-PAGE y, para algunas proteínas, análisis Western Blot para asegurar la más alta calidad.",
          },
        ],
      },
      performance: {
        title: "Rendimiento y Soporte",
        icon: "akar-icons:headphone",
        items: [
          {
            question: "¿Qué debo hacer si un producto no funciona como se esperaba?",
            answer:
              "Por favor, contacta con nuestro equipo de soporte técnico para obtener asistencia. Estamos aquí para ayudarte a resolver cualquier problema y asegurar el rendimiento óptimo de nuestros productos.",
          },
          {
            question: "¿Ofrecen formulaciones personalizadas o modificaciones de sus productos?",
            answer:
              'Sí, ofrecemos formulaciones personalizadas y modificaciones de nuestros productos. Toda la información relevante se puede encontrar en la sección "Servicios" de nuestro sitio web. Para información adicional, por favor contáctanos por correo electrónico a contact@genotic.com',
          },
          {
            question:
              "¿Están sus productos validados para aplicaciones específicas de investigación (por ejemplo, inmunoensayos, diagnósticos)?",
            answer:
              "Actualmente estamos en proceso de validar nuestros productos para aplicaciones específicas. En un futuro próximo, proporcionaremos información detallada indicando qué productos son adecuados para aplicaciones como Western Blot e inmunohistoquímica. Por favor, mantente atento a las actualizaciones.",
          },
          {
            question: "¿Cuál es su proceso de consistencia entre lotes?",
            answer:
              "Aseguramos una consistencia excepcional entre lotes produciendo nuestros anticuerpos mediante modificación genética de células y estableciendo líneas celulares estables, eliminando el uso de animales en la producción. También utilizamos medios químicamente definidos sin sustratos de origen animal, garantizando alta reproducibilidad y calidad consistente en todos los lotes.",
          },
          {
            question: "¿Cómo aseguran la reproducibilidad de los resultados usando sus productos?",
            answer:
              "Al emplear métodos de producción consistentes y usar medios químicamente definidos, garantizamos alta reproducibilidad de nuestros productos. Nuestros rigurosos procesos de control de calidad aseguran que puedas obtener resultados confiables y consistentes en tu investigación.",
          },
          {
            question:
              "¿Qué debo hacer si necesito ayuda con la integración del producto en mi flujo de trabajo?",
            answer:
              "Por favor, contacta con nuestro equipo de soporte técnico para obtener asistencia personalizada. Nuestros expertos están listos para ayudarte a integrar nuestros productos en tu flujo de trabajo y abordar cualquier pregunta o inquietud.",
          },
          {
            question: "¿Puedo programar una demostración de producto o consulta?",
            answer:
              "Sí, nos complacería organizar una demostración de producto o consulta contigo. Podemos programar videollamadas para discutir nuestros productos en detalle y responder cualquier pregunta que puedas tener. Por favor, envíanos un correo electrónico a general_inquires@genotic.com para programar un horario conveniente.",
          },
        ],
      },
      ordersAndShipping: {
        title: "Pedidos y Envíos",
        icon: "akar-icons:basket",
        items: [
          {
            question: "¿Cuáles son sus opciones de envío y cuánto tarda la entrega?",
            answer:
              "Ofrecemos varias opciones de envío para satisfacer tus necesidades. Los tiempos de entrega dependen del producto específico y tu ubicación. Se proporcionan estimaciones exactas de entrega en la página de cada producto, con actualizaciones de inventario en tiempo real para asegurar una disponibilidad precisa.",
          },
          {
            question: "¿Realizan envíos internacionales y hay restricciones?",
            answer:
              "Sí, realizamos envíos internacionales. Para realizar un pedido internacional, por favor contáctanos para obtener una cotización personalizada y discutir cualquier restricción potencial basada en las regulaciones de importación de tu país. Estamos dedicados a facilitar el acceso global a nuestros productos mientras cumplimos con todas las leyes y estándares aplicables.",
          },
          {
            question: "¿Cómo puedo rastrear mi pedido después de que se ha enviado?",
            answer:
              "Una vez que tu pedido ha sido despachado, te enviaremos un número de seguimiento por correo electrónico. Puedes usar este número para monitorear el progreso de tu envío a través del sistema de seguimiento del transportista. Si necesitas ayuda para rastrear tu pedido, no dudes en contactarnos.",
          },
          {
            question: "¿Qué métodos de pago aceptan?",
            answer: "Actualmente, el único método de pago disponible es la transferencia bancaria.",
          },
          {
            question:
              "¿Cómo puedo solicitar una cotización para pedidos al por mayor o personalizados?",
            answer:
              "Para pedidos al por mayor, por favor contáctanos por correo electrónico a contact@genotic.com",
          },
          {
            question:
              "¿Ofrecen descuentos para instituciones académicas, organizaciones sin fines de lucro o compras al por mayor?",
            answer:
              "Sí, ofrecemos descuentos para pedidos al por mayor, instituciones académicas y organizaciones sin fines de lucro. Podemos proporcionar cupones que otorgan descuentos. Por favor, contáctanos para discutir cómo podemos satisfacer tus necesidades.",
          },
          {
            question: "¿Puedo abrir una cuenta de crédito con su empresa?",
            answer:
              "Sí, ofrecemos cuentas de crédito a empresas con las que tenemos una cooperación establecida. Una vez que vemos que todo funciona bien, podemos organizar términos de pago posteriores al pedido. Por favor, contáctanos para discutir el proceso de solicitud.",
          },
          {
            question:
              "¿Cómo puedo actualizar la información de mi cuenta o gestionar mis pedidos en línea?",
            answer:
              "Puedes actualizar la información de tu cuenta y gestionar tus pedidos iniciando sesión en tu cuenta en nuestro sitio web. Todo es accesible a través del panel de usuario, donde puedes editar detalles personales, revisar el historial de pedidos y rastrear pedidos actuales.",
          },
        ],
      },
      policiesAndSupport: {
        title: "Políticas y Soporte",
        icon: "akar-icons:arrow-cycle",
        items: [
          {
            question: "¿Cuál es su política de devoluciones y reembolsos?",
            answer:
              "Ofrecemos una política de devolución de 30 días en todos nuestros productos. Si no estás completamente satisfecho—ya sea que el producto esté sin usar o no haya funcionado como se esperaba—proporcionamos un reembolso completo sin hacer preguntas. Confiamos en la calidad de nuestros productos y los respaldamos completamente. Tu satisfacción es nuestra máxima prioridad.",
          },
          {
            question: "¿Cuál es su política de garantía para los productos?",
            answer:
              "Respaldamos la calidad y fiabilidad de nuestros productos. Cada producto viene con información detallada y documentación de investigación que describe las pruebas específicas y controles de calidad realizados. Nuestros productos son consistentes entre lotes porque utilizamos métodos de producción sin animales. Si encuentras algún problema, ofrecemos un reembolso completo o reemplazo. Nuestra política de garantía refleja nuestro compromiso de proporcionar productos confiables y de alta calidad que satisfagan tus necesidades de investigación.",
          },
          {
            question:
              "¿Están involucrados en iniciativas de sostenibilidad o abastecimiento ético?",
            answer:
              "Aunque somos una empresa pequeña y aún no participamos oficialmente en iniciativas de sostenibilidad, estamos comprometidos con prácticas responsables. Estamos implementando soluciones de energía renovable, con el objetivo de operar fuera de la red. Además, no utilizamos animales en nuestro diseño de anticuerpos.",
          },
          {
            question:
              "¿Con quién debo contactar para soporte general al cliente o asistencia técnica?",
            answer:
              "Para soporte general al cliente o asistencia técnica, por favor consulta la información de contacto relevante a continuación.",
          },
          {
            question:
              "¿Cómo puedo actualizar la información de mi cuenta o gestionar mis pedidos en línea?",
            answer:
              "Puedes actualizar la información de tu cuenta y gestionar tus pedidos iniciando sesión en tu cuenta en nuestro sitio web. Todo es accesible a través del panel de usuario, donde puedes editar detalles personales, revisar el historial de pedidos y rastrear pedidos actuales.",
          },
        ],
      },
    },
  },
} satisfies SupportBase;
