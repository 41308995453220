import type { SupportBase } from "../types";

export default {
  title: "Support",
  subtitle: "Wie können wir Ihnen helfen?",
  description:
    "Finden Sie Antworten auf häufig gestellte Fragen und erhalten Sie die benötigte Unterstützung.",
  productDocumentation: {
    title: "Produktdokumentation",
    description:
      "Wir bieten umfassenden technischen Support für all unsere Produkte. Unser sachkundiges Team steht bereit, um Ihnen bei allen Fragen oder Problemen zu helfen. Für Hilfe zu Produktdetails konsultieren Sie bitte unsere Wissensdatenbank oder wenden Sie sich direkt an unser technisches Support-Team.",
    contact: {
      0: {
        title: "Technischen Support kontaktieren",
        email: "technical_support@genotic.com",
      },
    },
  },
  orderingAndPaymentSupport: {
    title: "Bestell- und Zahlungsunterstützung",
    description:
      "Wir bemühen uns, den Bestellvorgang reibungslos und unkompliziert zu gestalten. Ob Sie einen einzelnen Artikel kaufen oder eine Großbestellung aufgeben, wir bieten verschiedene Optionen, die Ihren Bedürfnissen entsprechen.",
    contact: {
      0: {
        title: "Technischen Support kontaktieren",
        email: "technical_support@genotic.com",
      },
    },
  },
  shippingAndDelivery: {
    title: "Versand und Lieferung",
    description:
      "Unsere Produkte werden weltweit mit Fokus auf schnelle, zuverlässige Lieferung verschickt. Wir arbeiten mit vertrauenswürdigen Versanddienstleistern zusammen, um sicherzustellen, dass Ihre Bestellung in optimalem Zustand ankommt.",
    tableItems: {
      1: {
        key: "Versandoptionen",
        value: "Noch zu bestätigen",
      },
      2: {
        key: "Bestellverfolgung",
        value: "Verfolgen Sie Ihre Sendung in Echtzeit, sobald sie versandt wurde.",
      },
      3: {
        key: "Internationaler Versand",
        value: "Noch zu bestätigen",
      },
    },
    contact: {
      0: {
        title: "Technischen Support kontaktieren",
        email: "technical_support@genotic.com",
      },
    },
  },
  contact: {
    title: "Kontaktieren Sie uns",
    subtitle: "Keine Antwort auf Ihre Frage gefunden? Kontaktieren Sie uns.",
    email: "contact@genotic.com",
  },
  faq: {
    sections: {
      storageAndHandling: {
        title: "Lagerung & Handhabung",
        icon: "akar-icons:headphone",
        items: [
          {
            question: "Was sind die empfohlenen Lagerungsbedingungen für Ihre Produkte?",
            answer:
              "Alle lyophilisierten Produkte sollten bei Raumtemperatur gelagert werden. Für Produkte in flüssiger Form wird eine Lagerung bei 4 °C oder im Gefrierschrank empfohlen. Bitte beachten Sie das detaillierte Produktdatenblatt für spezifische Lagerungsanweisungen für jedes Produkt.",
          },
          {
            question: "Wie sollte ich temperaturempfindliche Produkte handhaben?",
            answer:
              "Bitte befolgen Sie die spezifischen Lagerungsanweisungen im Produktdatenblatt. Generell sollten flüssige Produkte bei 4 °C oder im Gefrierschrank gelagert werden, um ihre Stabilität zu erhalten. Sachgemäße Lagerung gewährleistet die Wirksamkeit und Langlebigkeit des Produkts.",
          },
          {
            question: "Wie lange sind Ihre Produkte haltbar und wie wird dies bestimmt?",
            answer:
              "Die Haltbarkeit unserer Produkte hängt von der Produktionsmethode und den Lagerungsbedingungen ab. Für lyophilisierte Produkte beträgt die Haltbarkeit etwa 12 Monate bei Lagerung an einem kühlen, dunklen Ort. Für gefrorene Produkte werden spezifische Haltbarkeitsinformationen bereitgestellt. Da unsere Proteine neu entwickelt sind, führen wir laufende Stabilitätsstudien durch, um präzise Haltbarkeitsdaten zu liefern.",
          },
          {
            question:
              "Wie erhalte ich Analysenzertifikate (COA) oder Sicherheitsdatenblätter (SDS) für Ihre Produkte?",
            answer:
              "Alle erforderlichen Dokumente, einschließlich Sicherheitsdatenblatt, Datenblatt und Analysenzertifikat, sind direkt auf der jeweiligen Produktseite verfügbar. Diese Dokumente können für Ihre Bequemlichkeit eingesehen und heruntergeladen werden.",
          },
          {
            question:
              "Kann ich auf Protokolle, Anwendungshinweise oder technische Dokumentation für Ihre Produkte zugreifen?",
            answer:
              "Derzeit bieten wir keine Protokolle oder Benutzerhandbücher an, planen aber, diese in Zukunft hinzuzufügen. Allerdings stellen wir technische Dokumentation und eine Sammlung von Daten aus wissenschaftlichen Publikationen zur Verfügung, die auf den relevanten Produktseiten zu finden sind. Wir sind bestrebt, Ihre Forschungsbedürfnisse zu unterstützen und werden unsere Ressourcen kontinuierlich aktualisieren.",
          },
          {
            question:
              "Welche Qualitätskontrollmaßnahmen gibt es zur Gewährleistung der Produktkonsistenz?",
            answer:
              "Wir implementieren strenge Qualitätskontrollmaßnahmen für jede Produktcharge. Dies umfasst Gelelektrophorese zur Bewertung von Reinheit und Molekulargewicht, Biolayer-Interferometrie (BLI) zur Messung von Proteininteraktionen und Oberflächenplasmonenresonanz (SPR)-Analyse für bestimmte Proteine. Für Enzyme führen wir spezifische Enzymaktivitätstests durch, wie im Sicherheitsdatenblatt des Produkts beschrieben. Zusätzlich führen wir SDS-PAGE und für einige Proteine Western-Blot-Analysen durch, um höchste Qualität zu gewährleisten.",
          },
        ],
      },
      performance: {
        title: "Leistung & Support",
        icon: "akar-icons:headphone",
        items: [
          {
            question: "Was soll ich tun, wenn ein Produkt nicht wie erwartet funktioniert?",
            answer:
              "Bitte kontaktieren Sie unser technisches Support-Team für Unterstützung. Wir sind hier, um Ihnen bei der Lösung von Problemen zu helfen und optimale Leistung unserer Produkte sicherzustellen.",
          },
          {
            question:
              "Bieten Sie kundenspezifische Formulierungen oder Modifikationen Ihrer Produkte an?",
            answer:
              'Ja, wir bieten kundenspezifische Formulierungen und Modifikationen unserer Produkte an. Alle relevanten Informationen finden Sie im Bereich "Services" auf unserer Website. Für weitere Informationen kontaktieren Sie uns bitte per E-Mail unter contact@genotic.com',
          },
          {
            question:
              "Sind Ihre Produkte für spezifische Forschungsanwendungen validiert (z.B. Immunoassays, Diagnostik)?",
            answer:
              "Wir sind derzeit dabei, unsere Produkte für spezifische Anwendungen zu validieren. In naher Zukunft werden wir detaillierte Informationen bereitstellen, die angeben, welche Produkte für Anwendungen wie Western Blot und Immunhistochemie geeignet sind. Bitte bleiben Sie für Updates dran.",
          },
          {
            question: "Wie sieht Ihr Prozess für die Chargen-zu-Chargen-Konsistenz aus?",
            answer:
              "Wir gewährleisten außergewöhnliche Chargen-zu-Chargen-Konsistenz durch die Produktion unserer Antikörper mittels genetischer Modifikation von Zellen und Etablierung stabiler Zelllinien, wodurch der Einsatz von Tieren in der Produktion eliminiert wird. Wir verwenden auch chemisch definierte Medien ohne tierische Substrate, was hohe Reproduzierbarkeit und konsistente Qualität über alle Chargen hinweg garantiert.",
          },
          {
            question:
              "Wie stellen Sie die Reproduzierbarkeit der Ergebnisse mit Ihren Produkten sicher?",
            answer:
              "Durch den Einsatz konsistenter Produktionsmethoden und die Verwendung chemisch definierter Medien garantieren wir hohe Reproduzierbarkeit unserer Produkte. Unsere strengen Qualitätskontrollprozesse stellen sicher, dass Sie zuverlässige und konsistente Ergebnisse in Ihrer Forschung erzielen können.",
          },
          {
            question:
              "Was soll ich tun, wenn ich Hilfe bei der Integration des Produkts in meinen Workflow benötige?",
            answer:
              "Bitte kontaktieren Sie unser technisches Support-Team für persönliche Unterstützung. Unsere Experten stehen bereit, um Ihnen bei der Integration unserer Produkte in Ihren Workflow zu helfen und alle Fragen oder Bedenken zu klären.",
          },
          {
            question: "Kann ich eine Produktdemonstration oder Beratung vereinbaren?",
            answer:
              "Ja, wir würden uns freuen, eine Produktdemonstration oder Beratung mit Ihnen zu arrangieren. Wir können Videokonferenzen planen, um unsere Produkte im Detail zu besprechen und Ihre Fragen zu beantworten. Bitte senden Sie uns eine E-Mail an general_inquires@genotic.com, um einen passenden Termin zu vereinbaren.",
          },
        ],
      },
      ordersAndShipping: {
        title: "Bestellungen & Versand",
        icon: "akar-icons:basket",
        items: [
          {
            question: "Welche Versandoptionen gibt es und wie lange dauert die Lieferung?",
            answer:
              "Wir bieten verschiedene Versandoptionen, um Ihren Bedürfnissen gerecht zu werden. Die Lieferzeiten hängen vom spezifischen Produkt und Ihrem Standort ab. Genaue Lieferzeiten werden auf jeder Produktseite angegeben, mit Echtzeit-Bestandsaktualisierungen zur Gewährleistung genauer Verfügbarkeit.",
          },
          {
            question: "Versenden Sie international und gibt es Einschränkungen?",
            answer:
              "Ja, wir versenden international. Für eine internationale Bestellung kontaktieren Sie uns bitte für ein individuelles Angebot und um mögliche Einschränkungen basierend auf den Importbestimmungen Ihres Landes zu besprechen. Wir sind bestrebt, den globalen Zugang zu unseren Produkten zu erleichtern und dabei alle geltenden Gesetze und Standards einzuhalten.",
          },
          {
            question: "Wie kann ich meine Bestellung nach dem Versand verfolgen?",
            answer:
              "Sobald Ihre Bestellung versandt wurde, senden wir Ihnen eine Tracking-Nummer per E-Mail. Sie können diese Nummer verwenden, um den Fortschritt Ihrer Sendung über das Tracking-System des Versanddienstleisters zu überwachen. Wenn Sie Hilfe bei der Verfolgung Ihrer Bestellung benötigen, zögern Sie bitte nicht, uns zu kontaktieren.",
          },
          {
            question: "Welche Zahlungsmethoden akzeptieren Sie?",
            answer: "Derzeit ist die einzige verfügbare Zahlungsmethode die Banküberweisung.",
          },
          {
            question:
              "Wie kann ich ein Angebot für Großbestellungen oder kundenspezifische Bestellungen anfordern?",
            answer:
              "Für Großbestellungen kontaktieren Sie uns bitte per E-Mail unter contact@genotic.com",
          },
          {
            question:
              "Bieten Sie Rabatte für akademische Einrichtungen, gemeinnützige Organisationen oder Großeinkäufe an?",
            answer:
              "Ja, wir bieten Rabatte für Großbestellungen, akademische Einrichtungen und gemeinnützige Organisationen an. Wir können Gutscheine zur Verfügung stellen, die Rabatte gewähren. Bitte kontaktieren Sie uns, um zu besprechen, wie wir Ihren Bedürfnissen entgegenkommen können.",
          },
          {
            question: "Kann ich ein Kreditkonto bei Ihrem Unternehmen eröffnen?",
            answer:
              "Ja, wir bieten Kreditkonten für Unternehmen an, mit denen wir eine etablierte Zusammenarbeit haben. Sobald wir sehen, dass alles gut läuft, können wir Zahlungsbedingungen nach Bestellung vereinbaren. Bitte kontaktieren Sie uns, um den Antragsprozess zu besprechen.",
          },
          {
            question:
              "Wie kann ich meine Kontoinformationen aktualisieren oder meine Bestellungen online verwalten?",
            answer:
              "Sie können Ihre Kontoinformationen aktualisieren und Ihre Bestellungen verwalten, indem Sie sich in Ihrem Konto auf unserer Website einloggen. Alles ist über das Benutzerpanel zugänglich, wo Sie persönliche Details bearbeiten, Ihre Bestellhistorie einsehen und aktuelle Bestellungen verfolgen können.",
          },
        ],
      },
      policiesAndSupport: {
        title: "Richtlinien & Support",
        icon: "akar-icons:arrow-cycle",
        items: [
          {
            question: "Wie ist Ihre Rückgabe- und Erstattungspolitik?",
            answer:
              "Wir bieten eine 30-tägige Rückgabepolitik für alle unsere Produkte. Wenn Sie nicht vollständig zufrieden sind—ob das Produkt unbenutzt ist oder nicht wie erwartet funktioniert hat—gewähren wir eine vollständige Rückerstattung ohne Fragen zu stellen. Wir sind von der Qualität unserer Produkte überzeugt und stehen voll dahinter. Ihre Zufriedenheit hat oberste Priorität.",
          },
          {
            question: "Wie ist Ihre Garantiepolitik für Produkte?",
            answer:
              "Wir stehen für die Qualität und Zuverlässigkeit unserer Produkte ein. Jedes Produkt wird mit detaillierten Informationen und Forschungsdokumentation geliefert, die die spezifischen Tests und Qualitätskontrollen beschreiben. Unsere Produkte sind chargenübergreifend konsistent, da wir tierfreie Produktionsmethoden verwenden. Bei Problemen bieten wir eine vollständige Rückerstattung oder Ersatz. Unsere Garantiepolitik spiegelt unser Engagement wider, zuverlässige, hochwertige Produkte zu liefern, die Ihren Forschungsbedürfnissen entsprechen.",
          },
          {
            question:
              "Sind Sie in Nachhaltigkeits- oder ethische Beschaffungsinitiativen involviert?",
            answer:
              "Obwohl wir ein kleines Unternehmen sind und noch nicht offiziell an Nachhaltigkeitsinitiativen teilnehmen, setzen wir uns für verantwortungsvolle Praktiken ein. Wir implementieren erneuerbare Energielösungen mit dem Ziel, netzunabhängig zu operieren. Zusätzlich verwenden wir keine Tiere in unserem Antikörper-Design.",
          },
          {
            question:
              "Wen kontaktiere ich für allgemeinen Kundensupport oder technische Unterstützung?",
            answer:
              "Für allgemeinen Kundensupport oder technische Unterstützung finden Sie unten die relevanten Kontaktinformationen.",
          },
          {
            question:
              "Wie kann ich meine Kontoinformationen aktualisieren oder meine Bestellungen online verwalten?",
            answer:
              "Sie können Ihre Kontoinformationen aktualisieren und Ihre Bestellungen verwalten, indem Sie sich in Ihrem Konto auf unserer Website einloggen. Alles ist über das Benutzerpanel zugänglich, wo Sie persönliche Details bearbeiten, Ihre Bestellhistorie einsehen und aktuelle Bestellungen verfolgen können.",
          },
        ],
      },
    },
  },
} satisfies SupportBase;
