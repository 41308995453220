import type { I18nLocale } from "../en";
import countries from "./countries";
import error from "./error";
import saleor from "./saleor";
import support from "./support";
import zodI18n from "./zod";

export default {
  error,
  saleor,
  support,
  countries,
  zodI18n,
  title: "Genotic",
  common: {
    cancel: "Abbrechen",
    close: "Schließen",
    text: "Text",
    showMore: "Mehr anzeigen",
    showLess: "Weniger anzeigen",
    edit: "Bearbeiten",
    save: "Speichern",
    add: "Hinzufügen",
    day: "Tag | Tage",
    show: "Anzeigen",
    hide: "Verbergen",
    download: "Herunterladen | Herunterladungen",
    search: "Suchen...",
    scrollToTop: "Nach oben scrollen",
    delete: "Löschen",
    or: "oder",
    submit: "Absenden",
    submitting: "Absenden",
    open: "Öffnen",
  },
  unit: {
    pc: "Stk",
  },
  footer: {
    termsAndConditions: "Nutzungsbedingungen",
    privacyPolicy: "Datenschutzrichtlinie",
    new: "Neu",
    hiring: "Karriere",
  },
  header: {
    all: "Alle",
    myAccount: "Mein Konto",
    cart: "Warenkorb",
    signOut: "Abmelden",
    languageDisplay: "Deutsch (EUR)",
    countryWithCurrency: "Deutschland (EUR)",
    menu: "Menü",
    search: "Suche",
  },
  profile: {
    title: "Profil",
    info: "Info",
    addresses: "Adressen",
    giftCards: "Geschenkkarten",
    orders: {
      title: "Bestellungen",
      empty: "Sie haben noch keine Bestellungen erstellt",
    },
    editProfile: "Profil bearbeiten",
  },
  order: {
    cart: {
      empty: "Ihr Warenkorb ist leer",
      title: "Warenkorb",
      adjustItemQuantity: "Artikelmenge anpassen",
      shippingFrom: "Versand ab {amount}",
    },
    checkout: {
      title: "Bestellung",
      addAnythingToCartToContinue: "Fügen Sie etwas zum Warenkorb hinzu, um fortzufahren",
      goBackToStore: "Zurück zum Shop",
      delivery: "Lieferung",
      completeCheckout: "Bestellung abschließen",
      haveAPromoCode: "Haben Sie einen Gutscheincode?",
      promoCode: "Gutscheincode",
      specifyHowYouWantToPlaceYourOrder: "Geben Sie an, wie Sie Ihre Bestellung platzieren möchten",
      continueAsGuest: "Als Gast fortfahren",
      continueAsGuestDescription:
        "In jedem Moment des Bestellprozesses können Sie ein kostenloses Mitgliederprofil erstellen",
      iHaveAnAccount: "Ich habe bereits ein Konto",
      createAccount: "Konto erstellen",
      continueAsMember: "Als Mitglied fortfahren",
    },
    steps: {
      address: "Adresse",
      shipping: "Versand",
      payment: "Zahlung",
      continue: "Fortfahren",
      back: "Zurück",
    },
    paymentMethods: {
      title: "Zahlungsmethoden",
      bankTransfer: "Banküberweisung",
    },
    number: "Bestellnummer",
    status: "Status",
    paid: "Bezahlt",
    unpaid: "Unbezahlt",
    paymentStatus: "Zahlungsstatus",
    complete: "Bestellung abschließen",
    quantity: "Menge",
    info: "Bestellinformationen",
    arrives: "Lieferung",
    addToCart: "In den Warenkorb",
    addedToCart: "Produkt {product} wurde Ihrem Warenkorb hinzugefügt",
    goToCart: "Zum Warenkorb",
    goToCheckout: "Zur Kasse",
    shippingTo: "Versand nach",
    total: "Total",
    subtotal: "Zwischensumme",
    shipping: {
      title: "Versand",
      country: "Deutschland",
      noShippingMethodsAvailable: "Keine Versandmethoden verfügbar",
      shippingMethods: "Versandmethoden",
    },
    details: {
      title: "Bestelldetails",
      qty: "Menge",
    },
  },
  auth: {
    login: "Anmelden",
    register: "Registrieren",
    logout: "Abmelden",
    email: "E-Mail",
    password: "Passwort",
    password2: "Passwort bestätigen",
    firstName: "Vorname",
    lastName: "Nachname",
    dontHaveAnAccount: "Haben Sie kein Konto?",
    alreadyHaveAnAccount: "Haben Sie bereits ein Konto?",
    forgotPassword: "Passwort vergessen?",
    createNewAccount: "Neues Konto erstellen",
  },
  address: {
    title: "Adresse",
    shippingAddress: "Lieferadresse",
    billingAddress: "Rechnungsadresse",
    defaultAddress: "Standardadresse",
    thisAddressIsUsedForBothShippingAndBilling:
      "Diese Adresse wird für Lieferung und Rechnung verwendet",
    defaultForShippingAndBilling: "Standard für Lieferung und Rechnung",
    defaultShippingAddress: "Standardlieferadresse",
    defaultBillingAddress: "Standardrechnungsadresse",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Straße und Hausnummer",
    streetAddress2: "Adresszusatz",
    postalCode: "Postleitzahl",
    city: "Stadt",
    cityArea: "Bundesland",
    country: "Deutschland",
    email: "E-Mail",
    countryArea: "Bundesland",
    vat: "VAT",
    selectACountry: "Wählen Sie ein Land aus",
    companyName: "Firmen-/Institutionsname",
    phoneNumber: "Telefonnummer",
    billingMatchesShippingAddress: "Rechnungsadresse und Lieferadresse stimmen überein",
    editAddress: "Adresse bearbeiten",
    deleteAddress: "Adresse löschen",
    addAddress: "Adresse hinzufügen",
    noAddress: "Keine Adresse",
    noDefaultAddress: "Keine Standardadresse",
    empty: "Sie haben noch keine Adressen hinzugefügt",
    organizationInfo: "Organisationsinformationen",
  },
  product: {
    title: "Produkt | Produkte",
    addToCart: "In den Warenkorb",
    addedToCart: {
      toastTitle: "Produkt {product} wurde Ihrem Warenkorb hinzugefügt",
      toastDescription: "Sie können es jetzt dort ansehen...",
    },
    outOfStock: "Nicht vorrätig",
    synonyms: "Synonyme",
    noProductsFound: "Keine Produkte gefunden",
    description: {
      recommendedDilution: "Empfohlene Verdünnung",
      applications: "Anwendung | Anwendungen",
      dilution: "Verdünnung",
      testedApplications: "Getestete Anwendungen",
      targetSpecies: "Zielarten",
      targetAliases: "Zielalias",
      species: "Arten",
      uniProtIdAndGenId: "UniProt ID und Gen ID",
      images: "Bild | Bilder",
      publications: "Veröffentlichung | Veröffentlichungen",
      authors: "Autor | Autoren",
      performanceData: "Leistungsdaten",
      support: "Unterstützung",
      productSpecificProtocols: "Produktspezifische Protokolle",
      frequentlyAskedQuestions: "Häufig gestellte Fragen",
      contact: "Kontakt",
      productDetails: "Produktdetails",
      targetInfo: "Zielinformationen",
      relatedProducts: "Ähnliches Produkt | Ähnliche Produkte",
      files: "Dateien",
    },
  },
  ui: {
    availability: {
      available: "Verfügbar",
      unavailable: "Nicht verfügbar",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Kategorie | Kategorien",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Kategorien",
    noCategoriesFound: "Keine Kategorien gefunden",
  },
  warning: {
    thisActionCannotBeUndone: "Diese Aktion kann nicht rückgängig gemacht werden",
  },
  search: {
    closeSearchResults: "Suchergebnisse schließen",
    searchResultsInIndividualCategories: "Suchergebnisse in einzelnen Kategorien",
    resultsInIndividualCategories: "Suchergebnisse in einzelnen Kategorien",
  },
  notFound: {
    title: "This page is gone!",
    description:
      "At Genotic, we are committed to providing the support and resources you need to make the most of our products. Our support team is here to ensure you have access to the tools, information, and guidance required to achieve successful results.",
    button: "Go back",
  },
  lab: {
    title: "Labor",
    hero: {
      title: "Antikörperproduktion und Qualitätskontrolle in unserem Labor",
      description:
        "Wir glauben an Transparenz bei unseren Produktionsmethoden. Diese Übersicht beschreibt unsere Produktionspipeline - von der ersten Klonierung bis zur Reinigung und Qualitätskontrolle - und zeigt, wie wir Konsistenz in jeder Charge gewährleisten.",
    },
    process: {
      steps: {
        1: {
          title: "Klonierung",
          description:
            "Unsere KI-Algorithmen entwerfen variable Regionen von Antikörpern, die speziell darauf ausgerichtet sind, ausgewählte Antigene mit hoher Spezifität zu binden. Diese variablen Regionen werden dann in einen ausgewählten Vektor kloniert.",
          list: [],
          notes: [
            "In dieser Phase können wir die konstante Region des Antikörpers (z.B. humane, murine oder Kaninchen-Schwerkette) entsprechend den gewünschten Produktspezifikationen anpassen.",
            "Die entworfenen variablen Regionen können mit jeder gewählten schweren Kette fusioniert werden. Die resultierenden Vektoren werden in E. coli-Bakterien amplifiziert, um hohe Ausbeuten für nachfolgende Prozesse sicherzustellen.",
          ],
        },
        2: {
          title: "Produktion",
          description:
            "Der nächste Schritt beinhaltet die Transfektion von Bakterienzellen oder die Transformation von Säugerzellen, um die Produktion des gewünschten Antikörpers zu ermöglichen.",
          list: [],
          notes: [
            "Durch die Optimierung von Kulturbedingungen und Stimulationsprotokollen können wir die Produktionseffizienz deutlich steigern. Diese Phase gewährleistet Skalierbarkeit und Reproduzierbarkeit bei der Erzeugung hochwertiger Antikörper.",
          ],
        },
        3: {
          title: "Reinigung",
          description:
            "Die Antikörperreinigung erfolgt mittels Fast Protein Liquid Chromatography (FPLC)-Techniken. Jeder Antikörper durchläuft einen rigorosen zwei- oder dreistufigen Reinigungsprozess, typischerweise bestehend aus:",
          list: [
            "Immobilisierte Metallaffinitätschromatographie (IMAC)",
            "Ionenaustauschchromatographie (IEX)",
            "Größenausschlusschromatographie (SEC)",
          ],
          notes: [
            "Dieser mehrstufige Ansatz garantiert die Entfernung von Verunreinigungen und gewährleistet die Produktion von Antikörpern höchster Reinheit.",
          ],
        },
        4: {
          title: "Qualitätskontrolle",
          description:
            "Jeder Antikörper wird umfassenden Qualitätskontrollen unterzogen, um Konsistenz und Leistung sicherzustellen:",
          list: [
            {
              term: "Massenspektrometrie:",
              definition: "Wird zur Bestätigung der Aminosäuresequenz des Antikörpers verwendet.",
            },
            {
              term: "SDS-PAGE und Western Blot (WB):",
              definition: "Diese Techniken verifizieren das Molekulargewicht des Antikörpers.",
            },
            {
              term: "Bindungskinetik:",
              definition:
                "Die Interaktion zwischen Antikörper und Zielantigen wird analysiert und die Dissoziationskonstante (K𝐷) mittels Biolayer-Interferometrie (BLI) bestimmt.",
            },
          ],
          notes: [
            "Diese rigorose Qualitätskontrolle gewährleistet die funktionelle Zuverlässigkeit unserer Produkte.",
          ],
        },
        5: {
          title: "Konjugation",
          description:
            "Für spezifische Anwendungen werden ausgewählte Antikörper mit Fluorophoren oder Enzymen konjugiert, was ihre Nützlichkeit in Detektions- und Analysetechniken erhöht.",
          list: [],
          notes: [],
        },
        6: {
          title: "Lagerung",
          description:
            "Fertiggestellte Antikörper werden typischerweise lyophilisiert und gefroren gelagert, um ihre Stabilität und Funktionalität über längere Zeiträume zu erhalten.",
          list: [],
          notes: [],
        },
      },
    },
    excellence: {
      title: "Verpflichtung zur Exzellenz",
      heading:
        "Jeder Schritt der Antikörperproduktion in unserem Labor wird unter strenger Einhaltung etablierter Protokolle durchgeführt.",
      description:
        "Dies gewährleistet hohe Reproduzierbarkeit und außergewöhnliche Qualität in allen unseren Produkten und erfüllt die Anforderungen von Forschung und klinischen Anwendungen.",
    },
  },
  services: {
    title: "Dienstleistungen",
    hero: {
      title: "Design nach Bedarf",
      description: "Suchen Sie einen Antikörper, den Sie nicht finden können?",
      subDescription:
        "Wir entwickeln maßgeschneiderte Antikörper in-silico und liefern ein gebrauchsfertiges Produkt innerhalb von 20 Tagen.",
    },
    process: {
      steps: {
        1: {
          title: "Senden Sie Ihre eigene Struktur",
          description:
            "Alles, was wir benötigen, ist eine .pdb-Datei mit der 3D-Struktur, an die der Antikörper binden soll.",
        },
        2: {
          title: "Kosteneffizientes Verfahren",
          description:
            "Nach der Bewertung der Machbarkeit des kundenspezifischen Produkts bitten wir Sie um die Zahlung von 3500 €. Dann können wir mit der Produktion beginnen!",
        },
        3: {
          title: "KI-Design",
          description:
            "Mit 200 GPUs und unseren KI-Algorithmen berechnen wir die besten Kandidaten für Ihr Zielmolekül.",
        },
        4: {
          title: "Von in-silico ins Labor",
          description:
            "Wir produzieren die vielversprechendsten Kandidaten sorgfältig in unserem eigenen Labor.",
        },
        5: {
          title: "Spezifische und präzise Antikörper",
          description:
            "Um die beste Qualität unserer Produkte zu gewährleisten, werden unsere Produkte getestet und weisen eine durch SDS-PAGE-Ergebnisse bestimmte Mindesteinheit von 90% auf. Darüber hinaus führen wir Massenspektrometrie durch, stellen sicher, dass die analytische Umkehrphase einen einzelnen, scharfen Peak aufweist und Endotoxine unter der Nachweisgrenze liegen.",
        },
        6: {
          title: "Risikofreie Garantie",
          description:
            "Um die beste Qualität unserer Produkte zu gewährleisten, werden unsere Produkte getestet und weisen eine durch SDS-PAGE-Ergebnisse bestimmte Mindesteinheit von 90% auf. Darüber hinaus führen wir Massenspektrometrie durch, stellen sicher, dass die analytische Umkehrphase einen einzelnen, scharfen Peak aufweist und Endotoxine unter der Nachweisgrenze liegen.",
        },
      },
      confirmation:
        "Innerhalb von 48 Stunden bestätigen wir, ob wir den Antikörper herstellen können",
      delivery: "Nach maximal 3 Wochen erhalten Sie den Antikörper",
    },
    contact: {
      title: "Kontaktieren Sie uns",
      requestTitle: "Maßgeschneiderten Antikörper anfragen",
      description:
        "Erzählen Sie uns von Ihrem Projekt. Wir stellen Ihnen detaillierte Einreichungsanweisungen zur Verfügung.",
      email: "contact@genotic.com",
    },
    communication: {
      title: "Transparente Kommunikation",
      description:
        "Während des dreiwöchigen Produktionsprozesses Ihrer maßgeschneiderten Antikörper informieren wir Sie regelmäßig über jede Fertigungsstufe, vom Design bis zur endgültigen Reinigung. Unser automatisiertes Benachrichtigungssystem hält Sie über wichtige Meilensteine der Produktion und Qualitätskontrollergebnisse auf dem Laufenden.",
    },
    faq: {
      title: "Häufig gestellte Fragen",
      items: [
        {
          question: "Welche Dokumentation wird mit dem Endprodukt geliefert?",
          answer:
            "Jeder Antikörper wird mit einem umfassenden Dokumentationspaket geliefert, das Reinheitsanalyse (SDS-PAGE), Massenspektrometrie-Ergebnisse und analytische Umkehrphasen-Chromatographiedaten enthält. Wir stellen auch detaillierte Informationen zu Lagerungsbedingungen und Handhabungsempfehlungen zur Verfügung.",
        },
        {
          question: "Wie hoch ist die Erfolgsquote Ihres kundenspezifischen Antikörper-Designs?",
          answer:
            "Unser KI-gesteuerter Ansatz hat eine Erfolgsquote von über 90% bei der Herstellung von Antikörpern, die die spezifizierten Bindungsanforderungen erfüllen oder übertreffen. Wenn wir feststellen, dass wir keinen Antikörper herstellen können, der Ihren Anforderungen entspricht, informieren wir Sie innerhalb von 48 Stunden und erstatten den vollen Betrag zurück.",
        },
        {
          question: "Können Sie Antikörper für komplexe oder anspruchsvolle Ziele entwickeln?",
          answer:
            "Ja, unsere KI-Algorithmen sind besonders effektiv bei komplexen Zielen. Wir haben erfolgreich Antikörper für traditionell schwierige Ziele entwickelt, einschließlich Membranproteine, Konformationsepitope und hochähnliche Proteinvarianten.",
        },
        {
          question: "Was passiert, wenn der Antikörper die Spezifikationen nicht erfüllt?",
          answer:
            "Wir bieten eine 100%ige Zufriedenheitsgarantie. Wenn der Antikörper die vereinbarten Spezifikationen nicht erfüllt, werden wir entweder kostenlos einen neuen Antikörper neu entwickeln und produzieren oder eine vollständige Rückerstattung gewähren.",
        },
      ],
    },
  },
  about: {
    title: "Über uns",
    intro:
      "Wir sind Genotic - ein Team von Wissenschaftlern und Ingenieuren, die an der Schnittstelle von KI und Antikörperdesign arbeiten. Unsere Mission ist es, die Möglichkeiten in der biowissenschaftlichen Forschung zu erweitern, indem wir tiefgreifende wissenschaftliche Expertise mit computationaler Innovation verbinden.",
    values: [
      {
        name: "Wissenschaft.",
        description:
          "Wir entwickeln maßgeschneiderte Antikörper für jedes Ziel, einschließlich solcher, die von traditionellen Herstellern übersehen werden. Unser Ziel ist es, Forschung in unerforschten Bereichen zu ermöglichen.",
      },
      {
        name: "Innovation.",
        description:
          "Wir haben monatelange Versuch-und-Irrtum-Laborarbeit durch computationale Präzision ersetzt. Unsere KI entwirft einsatzbereite Antikörper in Wochen, nicht in Jahren.",
      },
      {
        name: "Fortschritt.",
        description:
          "Wir wachsen durch validierte Ergebnisse, nicht durch Versprechen. Jede validierte Implementierung führt uns zu neuen Möglichkeiten.",
      },
    ],
    mission: {
      title: "Unsere Mission",
      paragraph1:
        "Wir streben danach, das revolutionäre Potenzial der KI zu nutzen, insbesondere die Raffinesse großer Sprachmodelle und die Dynamik von Graph-Convolutional Neural Networks, um den Kurs unserer gemeinsamen Zukunft zu bestimmen.",
      paragraph2:
        "Wir sind von der außergewöhnlichen Fähigkeit der KI überzeugt, neue Antikörper zu entwickeln, das Tempo wissenschaftlicher Entdeckungen zu beschleunigen und lebensbedrohliche Krankheiten zu bekämpfen. In diesen immensen Fähigkeiten sehen wir das Versprechen von Heilung und Hoffnung für die gesamte Menschheit.",
    },
    beyondBusiness: {
      title: "Jenseits des Geschäfts",
      commercial: {
        title: "Wirtschaftlicher Erfolg treibt wissenschaftlichen Fortschritt.",
        description:
          "Wir glauben, dass wirtschaftlicher Erfolg und wissenschaftlicher Fortschritt sich gegenseitig verstärken können. Unser Geschäftsmodell basiert auf kontinuierlichem wissenschaftlichem Fortschritt, der es uns ermöglicht, neue Grenzen im Computational Design zu erforschen und gleichzeitig breitere Forschungsinitiativen zu unterstützen. Dieser Ansatz hält uns fokussiert auf langfristige wissenschaftliche Wirkung statt auf kurzfristige Gewinne.",
      },
      science: {
        title: "Ein begrenztes Budget sollte große Wissenschaft nicht einschränken.",
        description:
          "Obwohl wir als kommerzielles Unternehmen agieren, suchen wir aktiv nach Partnerschaften mit Wissenschaftlern, die vielversprechende, aber unterfinanzierte Bereiche erforschen. Wenn Sie Potenzial für den Einsatz unserer Technologie in Ihrer Forschung sehen, ermutigen wir Sie, uns unabhängig von Ihrer Finanzierungssituation zu kontaktieren.",
      },
    },
    contact: {
      title: "Kontakt",
      subtitle: "Kooperationsmöglichkeiten besprechen",
    },
    numbers: {
      title: "Genotic in Zahlen",
      items: [
        {
          unit: "",
          description: "Entwickelte Antikörper",
        },
        {
          unit: "Tage",
          description: "Produktionszeit für maßgeschneiderte Antikörper",
        },
        {
          unit: "Jahre",
          description: "Erfahrung im Antikörperdesign",
        },
      ],
    },
    guarantee: {
      title: "Unsere Garantie",
      subtitle:
        "Im Herzen unseres Unternehmens steht ein unerschütterliches Engagement für Qualität.",
      description:
        "Wir garantieren die Leistung jedes von uns produzierten Antikörpers, weil wir verstehen, dass zuverlässige Werkzeuge fundamental für den Forschungserfolg sind. Dies ist nicht nur ein Qualitätsstandard - es ist unser Grundprinzip, das alles leitet, was wir tun.",
    },
  },
  home: {
    hero: {
      title: "KI-Antikörper Revolution",
      description:
        "Greifen Sie auf unsere wachsende Bibliothek von über 1.200 KI-entwickelten Antikörpern zu. Benötigen Sie etwas Spezifisches? Wir können maßgeschneiderte Antikörper für Ihre einzigartigen Ziele entwickeln.",
    },
    ourApproach: {
      title: "Unser Ansatz",
      items: [
        {
          title: "KI-Design",
          description:
            "KI-Algorithmen ermöglichen sowohl schnelleres Standard-Antikörper-Design als auch maßgeschneiderte Lösungen für neue Ziele.",
        },
        {
          title: "Schnelle Produktion",
          description:
            "Unsere Design- und Produktionspipelines ermöglichen es uns, neue Antikörper innerhalb von Wochen, nicht Jahren, zu erstellen.",
        },
        {
          title: "Tierfreies Design",
          description:
            "Durch den Einsatz von Computertechniken gewährleisten wir ein ethisches Produktdesign ohne Tierversuche.",
        },
        {
          title: "Garantierte Qualität",
          description:
            "Unsere Produkte durchlaufen strenge Tests mit Verfahren wie SDS-PAGE, Western Blot oder Massenspektrometrie.",
        },
      ],
    },
    products: {
      title: "Unsere Produkte",
      categories: {
        antibodies: "Antikörper",
        proteins: "Proteine",
        enzymes: "Enzyme",
      },
    },
    testimonials: {
      title: "Referenzen",
      navigation: {
        previous: "Vorheriges Testimonial",
        next: "Nächstes Testimonial",
      },
    },
    designOnTheFly: {
      title: "Design nach Bedarf",
      heading: "Maßgeschneiderte Antikörper bestellen",
      description:
        "Greifen Sie auf unsere wachsende Bibliothek von über 1.200 KI-entwickelten Antikörpern zu. Benötigen Sie etwas Spezifisches? Wir können maßgeschneiderte Antikörper für Ihre einzigartigen Ziele entwickeln.",
      learnMore: "Mehr erfahren",
    },
  },
} satisfies I18nLocale;
