import type { I18nLocale } from "../en";
import countries from "./countries";
import error from "./error";
import saleor from "./saleor";
import support from "./support";
import zodI18n from "./zod";

export default {
  support,
  saleor,
  error,
  zodI18n,
  countries,
  title: "Genotic",
  common: {
    cancel: "Annuler",
    text: "Texte",
    edit: "Modifier",
    save: "Enregistrer",
    add: "Ajouter",
    showMore: "Afficher plus",
    showLess: "Afficher moins",
    day: "Jour | Jours",
    show: "Afficher",
    close: "Fermer",
    hide: "Masquer",
    download: "Télécharger | Téléchargements",
    search: "Rechercher...",
    scrollToTop: "Défiler vers le haut",
    delete: "Supprimer",
    or: "ou",
    submit: "Valider",
    submitting: "Validation en cours",
    open: "Ouvrir",
  },
  unit: {
    pc: "pce",
  },
  footer: {
    termsAndConditions: "Termes et conditions",
    privacyPolicy: "Politique de confidentialité",
    new: "Nouveau",
    hiring: "Nous recrutons !",
  },
  header: {
    all: "Tous",
    myAccount: "Mon compte",
    cart: "Panier",
    signOut: "Se déconnecter",
    languageDisplay: "Français (EUR)",
    countryWithCurrency: "France (EUR)",
    menu: "Menu",
    search: "Rechercher",
  },
  search: {
    closeSearchResults: "Fermer les résultats de recherche",
    searchResultsInIndividualCategories: "Résultats de recherche dans les catégories individuelles",
    resultsInIndividualCategories: "Résultats dans les catégories individuelles",
  },
  profile: {
    title: "Profil",
    info: "Infos",
    addresses: "Adresses",
    giftCards: "Cartes cadeaux",
    orders: {
      title: "Commandes",
      empty: "Vous n'avez pas créé de commandes",
    },
    editProfile: "Editer le profil",
  },
  address: {
    title: "Adresse",
    shippingAddress: "Adresse de livraison | Adresses de livraison",
    billingAddress: "Adresse de facturation | Adresses de facturation",
    defaultAddress: "Adresse par défaut",
    thisAddressIsUsedForBothShippingAndBilling:
      "Cette adresse est utilisée pour les livraisons et les facturations",
    defaultForShippingAndBilling: "Par défaut pour les livraisons et les facturations",
    defaultShippingAddress: "Adresse de livraison par défaut",
    defaultBillingAddress: "Adresse de facturation par défaut",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Adresse ligne 1",
    streetAddress2: "Adresse ligne 2",
    postalCode: "Code postal",
    city: "Ville",
    cityArea: "Zone de la ville",
    email: "@:auth.email",
    country: "Pays",
    countryArea: "État / Province",
    vat: "VAT",
    selectACountry: "Sélectionnez un pays",
    companyName: "Nom de l'entreprise/institution",
    phoneNumber: "Numéro de téléphone",
    billingMatchesShippingAddress: "L'adresse de facturation correspond à l'adresse de livraison",
    editAddress: "Modifier l'adresse",
    deleteAddress: "Supprimer l'adresse",
    addAddress: "Ajouter une adresse",
    noAddress: "Aucune adresse",
    empty: "Vous n'avez pas d'adresse",
    noDefaultAddress: "Aucune adresse par défaut",
    organizationInfo: "Información de la organización",
  },
  order: {
    cart: {
      empty: "Votre panier est vide",
      title: "Panier",
      adjustItemQuantity: "Ajuster la quantité d'article",
      shippingFrom: "Expédition à partir de {amount}",
    },
    checkout: {
      title: "Commande",
      addAnythingToCartToContinue: "Ajoutez quelque chose à votre panier pour continuer",
      goBackToStore: "Retour à la boutique",
      delivery: "Livraison",
      completeCheckout: "Terminer la commande",
      haveAPromoCode: "J'ai un code promo",
      promoCode: "Code promo",
      specifyHowYouWantToPlaceYourOrder: "Indiquez comment vous souhaitez placer votre commande",
      continueAsGuest: "Continuer en tant qu'invité",
      continueAsGuestDescription:
        "Dans chaque moment du processus de commande, vous pouvez créer un profil membre gratuit",
      iHaveAnAccount: "J'ai un compte",
      createAccount: "Créer un compte",
      continueAsMember: "Continuer en tant que membre",
    },
    steps: {
      address: "Adresse",
      shipping: "Livraison",
      payment: "Paiement",
      continue: "Continuer",
      back: "Retour",
    },
    paymentMethods: {
      title: "Moyens de paiement",
      bankTransfer: "Transfert bancaire",
    },
    number: "Numéro de commande",
    status: "Statut",
    paid: "Payée",
    unpaid: "Impayée",
    paymentStatus: "Statut de paiement",
    complete: "Commande terminée",
    quantity: "Quantité",
    info: "Informations sur la commande",
    arrives: "arrive",
    addToCart: "Ajouter au panier",
    addedToCart: "Le produit {product} a été ajouté à votre panier",
    goToCart: "Aller au panier",
    goToCheckout: "Passer à la caisse",
    shippingTo: "Expédition vers",
    total: "Total",
    subtotal: "Sous-total",
    shipping: {
      title: "livraison",
      country: "France",
      noShippingMethodsAvailable: "Aucune méthode de livraison disponible",
      shippingMethods: "Méthodes d'expédition",
    },
    details: {
      title: "Détails de la commande",
      qty: "Qté",
    },
  },
  auth: {
    login: "Connexion",
    register: "Inscription",
    logout: "Déconnexion",
    email: "E-mail",
    password: "Mot de passe",
    password2: "Confirmer le mot de passe",
    firstName: "Prénom",
    lastName: "Nom",
    alreadyHaveAnAccount: "Vous avez déjà un compte ?",
    dontHaveAnAccount: "Vous n'avez pas de compte ?",
    forgotPassword: "Mot de passe oublié",
    createNewAccount: "Créer un compte",
  },
  product: {
    title: "Produit | Produits",
    addToCart: "Ajouter au panier",
    addedToCart: {
      toastTitle: "Le produit {product} a été ajouté à votre panier",
      toastDescription: "Vous pouvez maintenant le voir là...",
    },
    outOfStock: "Rupture de stock",
    synonyms: "Synonymes",
    noProductsFound: "Aucun produit trouvé",
    description: {
      recommendedDilution: "Dilution recommandée",
      applications: "Application | Applications",
      dilution: "Dilution",
      testedApplications: "Applications testées",
      targetSpecies: "Espèces cibles",
      targetAliases: "Alias cibles",
      species: "Espèces",
      uniProtIdAndGenId: "ID UniProt et ID Génétique",
      images: "Image | Images",
      publications: "Publication | Publications",
      authors: "Auteur | Auteurs",
      performanceData: "Données de performance",
      support: "Support",
      productSpecificProtocols: "Protocoles spécifiques au produit",
      frequentlyAskedQuestions: "Questions fréquentes",
      contact: "Contact",
      productDetails: "Détails du produit",
      targetInfo: "Informations sur la cible",
      relatedProducts: "Produit lié | Produits liés",
      files: "Fichiers",
    },
  },
  ui: {
    availability: {
      available: "Disponible",
      unavailable: "Indisponible",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Catégorie | Catégories",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Catégories",
    noCategoriesFound: "Aucune catégorie trouvée",
  },
  warning: {
    thisActionCannotBeUndone: "Cette action ne peut pas être annulée",
  },
  notFound: {
    title: "This page is gone!",
    description:
      "At Genotic, we are committed to providing the support and resources you need to make the most of our products. Our support team is here to ensure you have access to the tools, information, and guidance required to achieve successful results.",
    button: "Go back",
  },
  lab: {
    title: "Laboratoire",
    hero: {
      title: "Production d'anticorps et contrôle qualité dans notre laboratoire",
      description:
        "Nous croyons en la transparence de nos méthodes de production. Cette vue d'ensemble décrit notre chaîne de production, du clonage initial à la purification et au contrôle qualité, montrant comment nous assurons la cohérence dans chaque lot.",
    },
    process: {
      steps: {
        1: {
          title: "Clonage",
          description:
            "Nos algorithmes d'IA conçoivent des régions variables d'anticorps spécifiquement adaptées pour se lier aux antigènes sélectionnés avec une haute spécificité. Ces régions variables sont ensuite clonées dans un vecteur sélectionné.",
          list: [],
          notes: [
            "À cette étape, nous pouvons adapter la région constante de l'anticorps (par exemple, chaîne lourde humaine, murine ou de lapin) selon les spécifications souhaitées du produit.",
            "Les régions variables conçues peuvent être fusionnées avec n'importe quelle chaîne lourde choisie. Les vecteurs résultants sont amplifiés dans des bactéries E. coli pour obtenir des rendements élevés pour les processus ultérieurs.",
          ],
        },
        2: {
          title: "Production",
          description:
            "L'étape suivante implique la transfection de cellules bactériennes ou la transformation de cellules mammifères pour permettre la production de l'anticorps souhaité.",
          list: [],
          notes: [
            "En optimisant les conditions de culture et les protocoles de stimulation, nous pouvons augmenter significativement l'efficacité de production. Cette étape assure l'évolutivité et la reproductibilité dans la génération d'anticorps de haute qualité.",
          ],
        },
        3: {
          title: "Purification",
          description:
            "La purification des anticorps est réalisée par chromatographie liquide rapide des protéines (FPLC). Chaque anticorps subit un processus de purification rigoureux en deux ou trois étapes, comprenant généralement :",
          list: [
            "Chromatographie d'affinité sur métal immobilisé (IMAC)",
            "Chromatographie échangeuse d'ions (IEX)",
            "Chromatographie d'exclusion stérique (SEC)",
          ],
          notes: [
            "Cette approche multi-étapes garantit l'élimination des impuretés et assure la production d'anticorps de la plus haute pureté.",
          ],
        },
        4: {
          title: "Contrôle Qualité",
          description:
            "Chaque anticorps est soumis à des tests de contrôle qualité complets pour assurer la cohérence et la performance :",
          list: [
            {
              term: "Spectrométrie de masse :",
              definition: "Utilisée pour confirmer la séquence d'acides aminés de l'anticorps.",
            },
            {
              term: "SDS-PAGE et Western Blot (WB) :",
              definition: "Ces techniques vérifient le poids moléculaire de l'anticorps.",
            },
            {
              term: "Cinétique de liaison :",
              definition:
                "L'interaction entre l'anticorps et son antigène cible est analysée, et la constante de dissociation (K𝐷) est déterminée par interférométrie bio-couche (BLI).",
            },
          ],
          notes: [
            "Ce contrôle qualité rigoureux assure la fiabilité fonctionnelle de nos produits.",
          ],
        },
        5: {
          title: "Conjugaison",
          description:
            "Pour des applications spécifiques, certains anticorps sont conjugués avec des fluorophores ou des enzymes, améliorant leur utilité dans les techniques de détection et d'analyse.",
          list: [],
          notes: [],
        },
        6: {
          title: "Stockage",
          description:
            "Les anticorps finalisés sont généralement lyophilisés et stockés congelés pour maintenir leur stabilité et leur fonctionnalité sur de longues périodes.",
          list: [],
          notes: [],
        },
      },
    },
    excellence: {
      title: "Engagement envers l'excellence",
      heading:
        "Chaque étape de la production d'anticorps dans notre laboratoire est réalisée en suivant strictement des protocoles établis.",
      description:
        "Cela assure une haute reproductibilité et une qualité exceptionnelle dans tous nos produits, répondant aux exigences de la recherche et des applications cliniques.",
    },
  },
  services: {
    title: "Services",
    hero: {
      title: "Conception instantanée",
      description: "Vous avez besoin d'un anticorps que vous ne trouvez pas ?",
      subDescription:
        "Nous concevons des anticorps personnalisés in-silico, livrant un produit prêt à l'emploi en 20 jours.",
    },
    process: {
      steps: {
        1: {
          title: "Soumettez votre structure",
          description:
            "Tout ce dont nous avons besoin est un fichier .pdb avec la structure 3D à laquelle vous souhaitez que l'anticorps se lie.",
        },
        2: {
          title: "Procédure rentable",
          description:
            "Après avoir évalué la capacité de créer le produit personnalisé, nous vous demanderons de payer 3500 €. Ensuite, nous pouvons commencer la production !",
        },
        3: {
          title: "Conception par IA",
          description:
            "En utilisant 200 GPUs et nos algorithmes d'IA, nous calculerons les meilleurs candidats pour votre cible.",
        },
        4: {
          title: "De l'in-silico au laboratoire",
          description:
            "Nous produirons soigneusement les candidats les plus prometteurs dans notre propre laboratoire.",
        },
        5: {
          title: "Anticorps spécifiques et précis",
          description:
            "Pour garantir que nos produits sont de la plus haute qualité, nos produits sont testés et ont une pureté minimale de 90% déterminée par les résultats SDS-PAGE. De plus, nous effectuons une spectrométrie de masse, nous nous assurons que la phase inverse analytique présente un pic unique et net et que les endotoxines sont en dessous du niveau de détection.",
        },
        6: {
          title: "Garantie sans risque",
          description:
            "Pour garantir que nos produits sont de la plus haute qualité, nos produits sont testés et ont une pureté minimale de 90% déterminée par les résultats SDS-PAGE. De plus, nous effectuons une spectrométrie de masse, nous nous assurons que la phase inverse analytique présente un pic unique et net et que les endotoxines sont en dessous du niveau de détection.",
        },
      },
      confirmation: "Sous 48 heures, nous confirmerons si nous pouvons créer l'anticorps",
      delivery: "Après un maximum de 3 semaines, vous recevrez l'anticorps",
    },
    contact: {
      title: "Contactez-nous",
      requestTitle: "Demander un anticorps personnalisé",
      description:
        "Parlez-nous de votre projet. Nous vous fournirons des instructions détaillées pour la soumission.",
      email: "contact@genotic.com",
    },
    communication: {
      title: "Communication transparente",
      description:
        "Pendant le processus de production de trois semaines de vos anticorps personnalisés, nous fournissons des mises à jour régulières sur chaque étape de fabrication, de la conception à la purification finale. Notre système de notification automatisé vous tient informé des étapes clés de la production et des résultats du contrôle qualité.",
    },
    faq: {
      title: "Questions fréquentes",
      items: [
        {
          question: "Quelle documentation est fournie avec le produit final ?",
          answer:
            "Chaque anticorps est livré avec un dossier de documentation complet comprenant l'analyse de pureté (SDS-PAGE), les résultats de spectrométrie de masse et les données de chromatographie en phase inverse analytique. Nous fournissons également des informations détaillées sur les conditions de stockage et les recommandations de manipulation.",
        },
        {
          question: "Quel est le taux de réussite de votre conception d'anticorps personnalisés ?",
          answer:
            "Notre approche basée sur l'IA a un taux de réussite de plus de 90% dans la création d'anticorps qui répondent ou dépassent les exigences de liaison spécifiées. Si nous déterminons que nous ne pouvons pas créer un anticorps qui répond à vos besoins, nous vous en informerons dans les 48 heures et fournirons un remboursement complet.",
        },
        {
          question: "Pouvez-vous concevoir des anticorps pour des cibles complexes ou difficiles ?",
          answer:
            "Oui, nos algorithmes d'IA sont particulièrement efficaces avec des cibles complexes. Nous avons conçu avec succès des anticorps pour des cibles traditionnellement difficiles, y compris des protéines membranaires, des épitopes conformationnels et des variantes de protéines hautement similaires.",
        },
        {
          question: "Que se passe-t-il si l'anticorps ne répond pas aux spécifications ?",
          answer:
            "Nous offrons une garantie de satisfaction à 100%. Si l'anticorps ne répond pas aux spécifications convenues, nous reconcevrions et produirions un nouvel anticorps sans frais supplémentaires ou fournirions un remboursement complet.",
        },
      ],
    },
  },
  about: {
    title: "À propos",
    intro:
      "Nous sommes Genotic - une équipe de scientifiques et d'ingénieurs travaillant à l'intersection de l'IA et de la conception d'anticorps. Notre mission est d'élargir les possibilités de la recherche en sciences de la vie en combinant une expertise scientifique approfondie et l'innovation computationnelle.",
    values: [
      {
        name: "Science.",
        description:
          "Nous concevons des anticorps personnalisés pour toute cible, y compris celles négligées par les producteurs traditionnels. Notre objectif est de permettre la recherche dans des domaines inexplorés.",
      },
      {
        name: "Innovation.",
        description:
          "Nous avons remplacé des mois de travail d'essais et d'erreurs en laboratoire par une précision computationnelle. Notre IA conçoit des anticorps prêts à l'emploi en quelques semaines, et non en années.",
      },
      {
        name: "Progrès.",
        description:
          "Nous grandissons grâce à des résultats validés, pas des promesses. Chaque implémentation validée nous guide vers de nouvelles possibilités.",
      },
    ],
    mission: {
      title: "Notre Mission",
      paragraph1:
        "Nous visons à utiliser le potentiel révolutionnaire de l'IA, en particulier la sophistication des grands modèles de langage et le dynamisme des réseaux de neurones convolutifs graphiques, pour tracer la voie de notre avenir commun.",
      paragraph2:
        "Nous sommes convaincus de la capacité extraordinaire de l'IA à concevoir de nouveaux anticorps, à accélérer le rythme de la découverte scientifique et à combattre les maladies mortelles. Dans ces immenses capacités, nous voyons la promesse de guérison et d'espoir pour toute l'humanité.",
    },
    beyondBusiness: {
      title: "Au-delà des Affaires",
      commercial: {
        title: "Le succès commercial au service du progrès scientifique.",
        description:
          "Nous croyons que le succès commercial et le progrès scientifique peuvent se renforcer mutuellement. Notre modèle d'affaires est construit autour de l'avancement scientifique continu, nous permettant d'explorer de nouvelles frontières dans la conception computationnelle tout en soutenant des initiatives de recherche plus larges. Cette approche nous maintient concentrés sur l'impact scientifique à long terme plutôt que sur les gains à court terme.",
      },
      science: {
        title: "Un budget limité ne devrait pas limiter la grande science.",
        description:
          "Bien que nous opérions comme une entité commerciale, nous recherchons activement des partenariats avec des scientifiques explorant des domaines prometteurs mais sous-financés. Si vous voyez un potentiel d'utilisation de notre technologie dans votre recherche, nous vous encourageons à nous contacter quelle que soit votre situation financière.",
      },
    },
    contact: {
      title: "Contactez-nous",
      subtitle: "Discuter des Opportunités de Collaboration",
    },
    numbers: {
      title: "Genotic en Chiffres",
      items: [
        {
          unit: "",
          description: "Anticorps conçus",
        },
        {
          unit: "jours",
          description: "Temps de production d'anticorps personnalisés",
        },
        {
          unit: "ans",
          description: "Expérience en conception d'anticorps",
        },
      ],
    },
    guarantee: {
      title: "Notre Garantie",
      subtitle:
        "Au cœur de notre entreprise se trouve un engagement inébranlable envers la qualité.",
      description:
        "Nous garantissons la performance de chaque anticorps que nous produisons car nous comprenons que des outils fiables sont fondamentaux pour le succès de la recherche. Ce n'est pas seulement une norme de qualité - c'est notre principe fondamental qui guide tout ce que nous faisons.",
    },
  },
  home: {
    hero: {
      title: "La révolution des anticorps par l'IA",
      description:
        "Accédez à notre bibliothèque croissante de plus de 1 200 anticorps conçus par IA. Besoin de quelque chose de spécifique ? Nous pouvons concevoir des anticorps personnalisés pour vos cibles uniques.",
    },
    ourApproach: {
      title: "Notre approche",
      items: [
        {
          title: "Conception par IA",
          description:
            "Les algorithmes d'IA permettent une conception plus rapide d'anticorps standard et des solutions personnalisées pour de nouvelles cibles.",
        },
        {
          title: "Production rapide",
          description:
            "Nos pipelines de conception et de production nous permettent de créer de nouveaux anticorps en quelques semaines, et non en années.",
        },
        {
          title: "Conception sans animaux",
          description:
            "En utilisant des techniques computationnelles, nous garantissons une conception éthique des produits, éliminant les expériences sur les animaux.",
        },
        {
          title: "Qualité garantie",
          description:
            "Nos produits subissent des tests rigoureux utilisant des procédures comme le SDS-PAGE, le Western Blot ou la spectrométrie de masse.",
        },
      ],
    },
    products: {
      title: "Nos produits",
      categories: {
        antibodies: "Anticorps",
        proteins: "Protéines",
        enzymes: "Enzymes",
      },
    },
    testimonials: {
      title: "Témoignages",
      navigation: {
        previous: "Témoignage précédent",
        next: "Témoignage suivant",
      },
    },
    designOnTheFly: {
      title: "Conception à la demande",
      heading: "Commander des anticorps personnalisés",
      description:
        "Accédez à notre bibliothèque croissante de plus de 1 200 anticorps conçus par IA. Besoin de quelque chose de spécifique ? Nous pouvons concevoir des anticorps personnalisés pour vos cibles uniques.",
      learnMore: "En savoir plus",
    },
  },
} satisfies I18nLocale;
