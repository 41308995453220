import countries from "./countries";
import error from "./error";
import saleor from "./saleor";
import support from "./support";
import zodI18n from "./zod";

const en = {
  support,
  saleor,
  error,
  zodI18n,
  countries,
  title: "Genotic",
  common: {
    cancel: "Cancel",
    close: "Close",
    text: "Text",
    edit: "Edit",
    save: "Save",
    add: "Add",
    show: "Show",
    showMore: "Show more",
    showLess: "Show less",
    hide: "Hide",
    open: "Open",
    day: "Day | Days",
    download: "Download | Downloads",
    delete: "Delete",
    search: "Search...",
    scrollToTop: "Scroll to top",
    or: "or",
    submit: "Submit",
    submitting: "Submitting",
  },
  unit: {
    pc: "pc",
  },
  footer: {
    termsAndConditions: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    new: "New",
    hiring: "We're hiring!",
  },
  header: {
    all: "All",
    myAccount: "My account",
    cart: "Cart",
    signOut: "Sign out",
    languageDisplay: "English (USD)",
    countryWithCurrency: "United States (USD)",
    search: "Search",
    menu: "Menu",
  },
  search: {
    searchResultsInIndividualCategories: "Search results in individual categories",
    closeSearchResults: "Close search results",
    resultsInIndividualCategories: "Results in individual categories",
  },
  profile: {
    title: "Profile",
    info: "Info",
    addresses: "Addresses",
    addAddress: "Add address",
    giftCards: "Gift cards",
    editProfile: "Edit profile",
    orders: {
      title: "Orders",
      empty: "You didn't create any orders",
    },
  },
  address: {
    title: "Address",
    shippingAddress: "Shipping address | Shipping addresses",
    billingAddress: "Billing address | Billing addresses",
    defaultAddress: "Default address",
    thisAddressIsUsedForBothShippingAndBilling:
      "This address is used for both shipping and billing",
    defaultForShippingAndBilling: "Default for shipping and billing",
    defaultShippingAddress: "Default shipping address",
    defaultBillingAddress: "Default billing address",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Address line 1",
    streetAddress2: "Address line 2",
    postalCode: "Postal code",
    city: "City",
    cityArea: "City area",
    email: "@:auth.email",
    country: "Country",
    countryArea: "State / Province",
    vat: "VAT",
    selectACountry: "Select a country",
    companyName: "Company/Institution",
    phoneNumber: "Phone number",
    billingMatchesShippingAddress: "Billing address matches shipping address",
    noAddress: "No address",
    noDefaultAddress: "No default address",
    empty: "You don't have any addresses",
    editAddress: "Edit address",
    addAddress: "Add address",
    deleteAddress: "Delete address",
    organizationInfo: "Organization info",
  },
  order: {
    cart: {
      empty: "Your cart is empty",
      title: "Cart",
      adjustItemQuantity: "Adjust item quantity",
      shippingFrom: "Shipping from {amount}",
    },
    checkout: {
      title: "Checkout",
      addAnythingToCartToContinue: "Add anything to your cart to continue",
      goBackToStore: "Go back to store",
      delivery: "Delivery",
      completeCheckout: "Complete checkout",
      haveAPromoCode: "Have a promo code?",
      promoCode: "Promo code",
      specifyHowYouWantToPlaceYourOrder: "Specify how you want to place your order",
      continueAsGuest: "Continue as guest",
      continueAsGuestDescription:
        "In every moment of the ordering process you can create a free member profile",
      iHaveAnAccount: "I have an account",
      createAccount: "Create account",
      continueAsMember: "Continue as member",
    },
    steps: {
      address: "Address",
      shipping: "Shipping",
      payment: "Payment",
      continue: "Continue",
      back: "Back",
    },
    paymentMethods: {
      title: "Payment methods",
      bankTransfer: "Bank transfer",
    },
    number: "Order number",
    status: "Status",
    paid: "Paid",
    unpaid: "Unpaid",
    paymentStatus: "Payment status",
    complete: "Complete order",
    quantity: "Quantity",
    info: "Ordering info",
    arrives: "Arrives",
    addToCart: "Add to cart",
    addedToCart: "Product {product} was added to your cart",
    goToCart: "Go to cart",
    goToCheckout: "Go to checkout",
    shippingTo: "Shipping to",
    total: "Total",
    subtotal: "Subtotal",
    shipping: {
      title: "Shipping",
      country: "United States of America",
      noShippingMethodsAvailable: "No shipping methods available",
      shippingMethods: "Shipping methods",
    },
    details: {
      title: "Order details",
      qty: "Qty",
      thankYou: "Thank you for your order, we will contact with you soon.",
    },
  },
  auth: {
    login: "Log in",
    register: "Sign up",
    logout: "Log out",
    email: "E-mail",
    password: "Password",
    password2: "Repeat password",
    firstName: "First name",
    lastName: "Last name",
    forgotPassword: {
      link: "Forgot password?",
      title: "Reset Password",
      description1:
        "Enter your e-mail address and we'll send you instructions to reset your password.",
      description2: "Change your password and log in again.",
      backToLogin: "Back to login",
      sendMeALink: "Send me a link",
      changePassword: "Change password",
      newPassword: "New password",
      checkEmail: "Check your e-mail for a reset link.",
      passwordChanged: "Password has been changed.",
    },
    loginPage: {
      title: "Log in",
      description:
        "At Genotic, we are committed to providing the support and resources you need to make the most of our products.",
    },
    createNewAccount: "Create new account",
    alreadyHaveAnAccount: "Already have an account?",
    dontHaveAnAccount: "Don't have an account?",
  },
  product: {
    title: "Product | Products",
    addToCart: "Add to cart",
    addedToCart: {
      toastTitle: "Product {product} was added to your cart",
      toastDescription: "You can now view it there ...",
    },
    outOfStock: "Out of stock",
    synonyms: "Synonyms",
    description: {
      recommendedDilution: "Recommended dilution",
      applications: "Application | Applications",
      dilution: "Dilution",
      testedApplications: "Tested applications",
      targetSpecies: "Target species",
      targetAliases: "Target aliases",
      species: "Species",
      uniProtIdAndGenId: "UniProt ID and Gen ID",
      images: "Image | Images",
      publications: "Publication | Publications",
      authors: "Author | Authors",
      performanceData: "Performance data",
      support: "Support", // it was removed in SupportSection but might be used somewhere else
      files: "Files",
      productSpecificProtocols: "Product specific protocols",
      frequentlyAskedQuestions: "Frequently asked questions",
      contact: "Contact",
      productDetails: "Product details",
      targetInfo: "Target info",
      relatedProducts: "Related product | Related products",
    },
    noProductsFound: "No products found",
  },
  ui: {
    availability: {
      available: "Available",
      unavailable: "Unavailable",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "@:categories.title",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Categories",
    noCategoriesFound: "No categories found",
  },
  warning: {
    thisActionCannotBeUndone: "This action cannot be undone",
  },
  notFound: {
    title: "This page is gone!",
    description:
      "At Genotic, we are committed to providing the support and resources you need to make the most of our products. Our support team is here to ensure you have access to the tools, information, and guidance required to achieve successful results.",
    button: "Go back",
  },
  lab: {
    title: "Lab",
    hero: {
      title: "Antibody Production and Quality Control in Our Laboratory",
      description:
        "We believe in transparency about our production methods. This overview details our production pipeline - from initial cloning through purification and quality control - showing how we ensure consistency in every batch.",
    },
    process: {
      steps: {
        1: {
          title: "Cloning",
          description:
            "Our AI-driven algorithms design variable regions of antibodies specifically tailored to bind selected antigens with high specificity. These variable regions are then cloned into a selected vector.",
          list: [],
          notes: [
            "At this stage, we can customize the antibody's constant region (e.g., human, murine, or rabbit heavy chain) according to the desired product specifications.",
            "The designed variable regions can be fused with any chosen heavy chain. The resulting vectors are amplified in E. coli bacteria to ensure high yields for subsequent processes.",
          ],
        },
        2: {
          title: "Production",
          description:
            "The next step involves the transfection of bacterial cells or the transformation of mammalian cells to enable the production of the desired antibody.",
          list: [],
          notes: [
            "By optimizing culture conditions and stimulation protocols, we can significantly enhance production efficiency. This stage ensures scalability and reproducibility in generating high-quality antibodies.",
          ],
        },
        3: {
          title: "Purification",
          description:
            "Antibody purification is carried out using fast protein liquid chromatography (FPLC) techniques. Each antibody undergoes a rigorous two- or three-step purification process, typically involving:",
          list: [
            "Immobilized Metal Affinity Chromatography (IMAC)",
            "Ion Exchange Chromatography (IEX)",
            "Size Exclusion Chromatography (SEC)",
          ],
          notes: [
            "This multi-stage approach guarantees the removal of impurities and ensures the production of antibodies with the highest purity.",
          ],
        },
        4: {
          title: "Quality Control",
          description:
            "Each antibody is subjected to comprehensive quality control testing to ensure consistency and performance:",
          list: [
            {
              term: "Mass Spectrometry:",
              definition: "Used to confirm the amino acid sequence of the antibody.",
            },
            {
              term: "SDS-PAGE and Western Blot (WB):",
              definition: "These techniques verify the molecular weight of the antibody.",
            },
            {
              term: "Binding Kinetics:",
              definition:
                "The interaction between the antibody and its target antigen is analyzed, and the dissociation constant (K𝐷) is determined using biolayer interferometry (BLI).",
            },
          ],
          notes: [
            "This rigorous quality control ensures the functional reliability of our products.",
          ],
        },
        5: {
          title: "Conjugation",
          description:
            "For specific applications, selected antibodies are conjugated with fluorophores or enzymes, enhancing their utility in detection and analysis techniques.",
          list: [],
          notes: [],
        },
        6: {
          title: "Storage",
          description:
            "Finalized antibodies are typically lyophilized and stored frozen to maintain their stability and functionality over extended periods.",
          list: [],
          notes: [],
        },
      },
    },
    excellence: {
      title: "Commitment to Excellence",
      heading:
        "Every stage of antibody production in our laboratory is performed under strict adherence to established protocols.",
      description:
        "This ensures high reproducibility and exceptional quality in all our products, meeting the demands of research and clinical applications.",
    },
  },
  services: {
    title: "Services",
    hero: {
      title: "Design on the fly",
      description: "Need an antibody that you can't find?",
      subDescription:
        "We design custom antibodies in-silico, delivering ready-to-use product in 20 days.",
    },
    process: {
      steps: {
        1: {
          title: "Send your own structure",
          description:
            "All we need is a .pdb file with the 3D structure to which you what the antibody to bind to.",
        },
        2: {
          title: "Cost efficient procedure",
          description:
            "After assessing the ability to create custom product we will ask you to pay 3500 €. Then we can start to produce!",
        },
        3: {
          title: "AI design",
          description:
            "Using 200 GPUs and our AI algorithms we will calculate the best candidates for your target.",
        },
        4: {
          title: "From in-silico to lab",
          description: "We will produce the most promising candidates carefully in our own lab.",
        },
        5: {
          title: "Specific and precise antibodies",
          description:
            "To ensure that our products are of the best quality, our products are tested and have minimum purity of 90% determined by SDS-PAGE results. Moreover, we conduct mass spectrometry, ensure that analytical reversed-phase has single, sharp peak and endotoxins are below level of detection.",
        },
        6: {
          title: "Risk-free Guarantee",
          description:
            "To ensure that our products are of the best quality, our products are tested and have minimum purity of 90% determined by SDS-PAGE results. Moreover, we conduct mass spectrometry, ensure that analytical reversed-phase has single, sharp peak and endotoxins are below level of detection.",
        },
      },
      confirmation: "Within 48h we will confirm if we can create the antibody",
      delivery: "After maximum 3 weeks the antibody will get to you",
    },
    contact: {
      title: "Contact us",
      requestTitle: "Request custom antibody",
      description: "Tell us about your project. We'll provide detailed submission instructions.",
      email: "contact@genotic.com",
    },
    communication: {
      title: "Transparent communication",
      description:
        "During the three-week production process of your custom antibodies, we provide regular status updates on each manufacturing stage, from design to final purification. Our automated notification system keeps you informed about key milestones of production and quality control results.",
    },
    faq: {
      title: "Frequently Asked Questions",
      items: [
        {
          question: "What documentation is provided with the final product?",
          answer:
            "Each antibody comes with a comprehensive documentation package including purity analysis (SDS-PAGE), mass spectrometry results, and analytical reversed-phase chromatography data. We also provide detailed information about storage conditions and handling recommendations.",
        },
        {
          question: "What is the success rate of your custom antibody design?",
          answer:
            "Our AI-driven approach has a success rate of over 90% in creating antibodies that meet or exceed the specified binding requirements. If we determine that we cannot create an antibody that meets your needs, we will inform you within 48 hours and provide a full refund.",
        },
        {
          question: "Can you design antibodies for complex or challenging targets?",
          answer:
            "Yes, our AI algorithms are particularly effective with complex targets. We've successfully designed antibodies for traditionally difficult targets including membrane proteins, conformational epitopes, and highly similar protein variants.",
        },
        {
          question: "What if the antibody doesn't meet the specifications?",
          answer:
            "We offer a 100% satisfaction guarantee. If the antibody doesn't meet the agreed-upon specifications, we will either redesign and produce a new antibody at no additional cost or provide a full refund.",
        },
      ],
    },
  },
  about: {
    title: "About",
    intro:
      "We are Genotic - a team of scientists and engineers working at the intersection of AI and antibody design. Our mission is to expand the possibilities in life science research by combining deep scientific expertise with computational innovation.",
    values: [
      {
        name: "Science.",
        description:
          "We design custom antibodies for any target, including those overlooked by traditional producers. Our goal is to enable research in unexplored domains.",
      },
      {
        name: "Innovation.",
        description:
          "We've replaced months of trial-and-error lab work with computational precision. Our AI designs ready to use antibodies in weeks, not years.",
      },
      {
        name: "Progress.",
        description:
          "We grow through validated results, not promises. Each validated implementation guides us toward new possibilities.",
      },
    ],
    mission: {
      title: "Our Mission",
      paragraph1:
        "We aim to utilize the revolutionary potential of AI, especially the sophistication of large language models and the dynamism of graph convolutional neural networks, to chart the course of our shared future.",
      paragraph2:
        "We are convinced of AI's extraordinary ability to design new antibodies, accelerate the pace of scientific discovery and combat life-threatening diseases. In these immense capabilities, we see the promise of healing and hope for all of humanity.",
    },
    beyondBusiness: {
      title: "Beyond Business",
      commercial: {
        title: "Commercial success driving scientific progress.",
        description:
          "We believe that commercial success and scientific progress can reinforce each other. Our business model is built around continuous scientific advancement, allowing us to explore new frontiers in computational design while supporting broader research initiatives. This approach keeps us focused on long-term scientific impact rather than short-term gains.",
      },
      science: {
        title: "Limited budget shouldn't limit great science.",
        description:
          "While we operate as a commercial entity, we actively seek partnerships with scientists exploring promising but underfunded areas. If you see potential for using our technology in your research, we encourage you to contact us regardless of your funding situation.",
      },
    },
    contact: {
      title: "Contact us",
      subtitle: "Discuss Collaboration Opportunities",
    },
    numbers: {
      title: "Genotic in Numbers",
      items: [
        {
          unit: "",
          description: "Designed antibodies",
        },
        {
          unit: "days",
          description: "Custom antibody production time",
        },
        {
          unit: "years",
          description: "Experience in antibody design",
        },
      ],
    },
    guarantee: {
      title: "Our Guarantee",
      subtitle: "At the heart of our company lies an unwavering commitment to quality.",
      description:
        "We guarantee the performance of every antibody we produce because we understand that reliable tools are fundamental to research success. This isn't just a quality standard - it's our core principle that drives everything we do.",
    },
  },
  home: {
    hero: {
      title: "AI Antibody revolution",
      description:
        "Access our growing library of 1,200+ AI-designed antibodies. Need something specific? We can design custom antibodies for your unique targets.",
    },
    ourApproach: {
      title: "Our approach",
      items: [
        {
          title: "AI design",
          description:
            "AI algorithms enable both faster standard antibody design and customized solutions for novel targets.",
        },
        {
          title: "Fast production",
          description:
            "Our design and production pipelines allow us to create novel antibodies within weeks, not years.",
        },
        {
          title: "Animal free design",
          description:
            "By using computational techniques, we ensure ethical product design, eliminating experiments on animals.",
        },
        {
          title: "Guaranteed quality",
          description:
            "Our products undergo rigorous testing using procedures like SDS-PAGE, Western Blot or Mass spectrometry.",
        },
      ],
    },
    products: {
      title: "Our products",
      categories: {
        antibodies: "Antibodies",
        proteins: "Proteins",
        enzymes: "Enzymes",
      },
    },
    testimonials: {
      title: "Testimonials",
      navigation: {
        previous: "Previous testimonial",
        next: "Next testimonial",
      },
    },
    designOnTheFly: {
      title: "Design on the fly",
      heading: "Order custom antibodies",
      description:
        "Access our growing library of 1,200+ AI-designed antibodies. Need something specific? We can design custom antibodies for your unique targets.",
      learnMore: "Learn more",
    },
  },
};

export type I18nLocale = typeof en;

export default en;
