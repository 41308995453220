const zod = {
  errors: {
    too_small: {
      string: {
        inclusive: "The string must contain at least {minimum} character(s)",
      },
    },
    invalid_type_received_undefined: "Required!",
    required_field: "This field is required",
    invalid_type: {
      string: {
        expected: "Expected a string, but received {received}",
      },
      number: {
        expected: "Expected a number, but received {received}",
      },
    },
    too_big: {
      string: {
        inclusive: "The string must contain at most {maximum} character(s)",
      },
    },
    invalid_string: {
      email: "Invalid email address",
      url: "Invalid URL",
    },
    custom: "Invalid input",
  },
  validations: {
    email: "Please enter a valid email address",
    phone: "Please enter a valid phone number",
  },
};

export type ZodI18n = typeof zod;

export default zod;
