import type { I18nLocale } from "../en";
import countries from "./countries";
import error from "./error";
import saleor from "./saleor";
import support from "./support";
import zodI18n from "./zod";

export default {
  support,
  saleor,
  error,
  zodI18n,
  countries,
  title: "Genotic",
  common: {
    cancel: "Anuluj",
    text: "Tekst",
    edit: "Edytuj",
    save: "Zapisz",
    showMore: "Pokaż więcej",
    showLess: "Pokaż mniej",
    add: "Dodaj",
    day: "Dzień | Dni",
    show: "Pokaż",
    hide: "Ukryj",
    close: "Zamknij",
    download: "Pobierz | Pobrane",
    search: "Szukaj...",
    scrollToTop: "Przewiń do góry",
    delete: "Usuń",
    or: "lub",
    submit: "Zatwierdź",
    submitting: "Zatwierdzanie",
    open: "Otwórz",
  },
  unit: {
    pc: "szt",
  },
  footer: {
    termsAndConditions: "Regulamin",
    privacyPolicy: "Polityka prywatności",
    new: "Nowe",
    hiring: "Zatrudniamy!",
  },
  header: {
    all: "Wszystko",
    myAccount: "Konto",
    cart: "Koszyk",
    signOut: "Wyloguj",
    languageDisplay: "Polski (PLN)",
    countryWithCurrency: "Polska (PLN)",
    menu: "Menu",
    search: "Szukaj",
  },
  search: {
    closeSearchResults: "Zamknij wyniki wyszukiwania",
    searchResultsInIndividualCategories: "Wyniki wyszukiwania w poszczególnych kategoriach",
    resultsInIndividualCategories: "Wyniki w poszczególnych kategoriach",
  },
  profile: {
    title: "Profil",
    info: "Informacje",
    addresses: "Adresy",
    addAddress: "Dodaj adres",
    giftCards: "Karty podarunkowe",
    orders: {
      title: "Zamówienia",
      empty: "Nie utworzono żadnych zamówień",
    },
    editProfile: "Edytuj profil",
  },
  address: {
    title: "Adres",
    shippingAddress: "Adres dostawy | Adresy dostawy",
    billingAddress: "Adres rozliczeniowy | Adresy rozliczeniowe",
    defaultAddress: "Adres domyślny",
    thisAddressIsUsedForBothShippingAndBilling: "Ten adres jest używany do wysyłki i rozliczenia",
    defaultForShippingAndBilling: "Domyślny dla wysyłki i rozliczenia",
    defaultShippingAddress: "Domyślny adres dostawy",
    defaultBillingAddress: "Domyślny adres rozliczeniowy",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Linia adresowa 1",
    streetAddress2: "Linia adresowa 2",
    postalCode: "Kod pocztowy",
    city: "Miasto",
    cityArea: "Obszar miasta",
    email: "@:auth.email",
    country: "Kraj",
    countryArea: "Stan / Województwo",
    vat: "VAT",
    selectACountry: "Wybierz kraj",
    companyName: "Nazwa firmy/instytucji",
    phoneNumber: "Numer telefonu",
    billingMatchesShippingAddress: "Adres rozliczeniowy taki sam jak adres dostawy",
    editAddress: "Edytuj adres",
    deleteAddress: "Usuń adres",
    addAddress: "Dodaj adres",
    noAddress: "Brak adresu",
    noDefaultAddress: "Brak domyślnego adresu",
    empty: "Nie dodano żadnych adresów",
    organizationInfo: "Informace o organizacji",
  },
  order: {
    cart: {
      empty: "Twój koszyk jest pusty",
      title: "Koszyk",
      adjustItemQuantity: "Dostosuj ilość produktu",
      shippingFrom: "Wysyłka od {amount}",
    },
    checkout: {
      title: "Zamówienie",
      addAnythingToCartToContinue: "Dodaj coś do koszyka, aby kontynuować",
      goBackToStore: "Wróć do sklepu",
      delivery: "Dostawa",
      completeCheckout: "Zakończ zamówienie",
      haveAPromoCode: "Masz kod promocyjny?",
      promoCode: "Kod promocyjny",
      specifyHowYouWantToPlaceYourOrder: "Określ, jak chcesz umieścić swoje zamówienie",
      continueAsGuest: "Kontynuuj jako gość",
      continueAsGuestDescription:
        "W każdej chwili procesu zamawiania możesz utworzyć bezpłatny profil członka",
      continueAsMember: "Kontynuuj jako członek",
      createAccount: "Utwórz konto",
      iHaveAnAccount: "Mam już konto",
    },
    steps: {
      address: "Adres",
      shipping: "Dostawa",
      payment: "Płatność",
      continue: "Kontynuuj",
      back: "Wstecz",
    },
    paymentMethods: {
      title: "Metody płatności",
      bankTransfer: "Przelew bankowy",
    },
    number: "Numer zamówienia",
    status: "Status",
    paid: "Opłacone",
    unpaid: "Nieopłacone",
    paymentStatus: "Status płatności",
    complete: "Złóż zamówienie",
    quantity: "Ilość",
    info: "Informacje o zamówieniu",
    arrives: "dostawa",
    addToCart: "Dodaj do koszyka",
    addedToCart: "Produkt {product} został dodany do twojego koszyka",
    goToCart: "Przejdź do koszyka",
    goToCheckout: "Przejdź do kasy",
    shippingTo: "Wysyłka do",
    total: "Suma",
    subtotal: "Suma częściowa",
    shipping: {
      title: "wysyłka",
      country: "Polska",
      noShippingMethodsAvailable: "Brak dostępnych metod dostawy",
      shippingMethods: "Metody wysyłki",
    },
    details: {
      title: "Szczegóły zamówienia",
      qty: "Ilość",
      thankYou: "Dziękujemy za złożenie zamówienia, skontaktujemy się z Tobą wkrótce.",
    },
  },
  auth: {
    login: "Zaloguj",
    register: "Zarejestruj się",
    logout: "Wyloguj się",
    email: "E-mail",
    password: "Hasło",
    password2: "Powtórz hasło",
    firstName: "Imię",
    lastName: "Nazwisko",
    alreadyHaveAnAccount: "Masz już konto?",
    dontHaveAnAccount: "Nie masz konta?",
    forgotPassword: {
      link: "Zapomniałeś hasła?",
      title: "Resetuj hasło",
      description1: "Wprowadź swój adres e-mail, a wyślemy Ci instrukcje resetowania hasła.",
      description2: "Zmień swoje hasło i zaloguj się ponownie.",
      backToLogin: "Powrót do logowania",
      sendMeALink: "Wyślij link",
      changePassword: "Zmień hasło",
      newPassword: "Nowe hasło",
      checkEmail: "Na e-mail został wysłany link do resetowania hasła.",
    },
    createNewAccount: "Utwórz nowe konto",
    passwordChanged: "Hasło zostało zmienione.",
  },
  product: {
    title: "Produkt | Produkty",
    addToCart: "Dodaj do koszyka",
    addedToCart: {
      toastTitle: "Produkt {product} został dodany do twojego koszyka",
      toastDescription: "Możesz teraz go tam zobaczyć...",
    },
    outOfStock: "Brak w magazynie",
    synonyms: "Synonimy",
    noProductsFound: "Nie znaleziono produktów",
    description: {
      recommendedDilution: "Zalecane rozcieńczenie",
      applications: "Zastosowanie | Zastosowania",
      dilution: "Rozcieńczenie",
      testedApplications: "Przetestowane zastosowania",
      targetSpecies: "Gatunki docelowe",
      targetAliases: "Aliasy docelowe",
      species: "Gatunki",
      uniProtIdAndGenId: "UniProt ID i Gen ID",
      images: "Obraz | Obrazy",
      publications: "Publikacja | Publikacje",
      authors: "Autor | Autorzy",
      performanceData: "Dane wydajności",
      support: "Wsparcie",
      productSpecificProtocols: "Specyficzne protokoły produktu",
      frequentlyAskedQuestions: "Często zadawane pytania",
      contact: "Kontakt",
      productDetails: "Szczegóły produktu",
      targetInfo: "Informacje o celu",
      relatedProducts: "Powiązany produkt | Powiązane produkty",
      files: "Pliki",
    },
  },
  ui: {
    availability: {
      available: "Dostępny",
      unavailable: "Niedostępny",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Kategoria | Kategorie",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Kategorie",
    noCategoriesFound: "Nie znaleziono żadnej kategorii",
  },
  warning: {
    thisActionCannotBeUndone: "Ta akcja nie może być cofnięta",
  },
  notFound: {
    title: "This page is gone!",
    description:
      "At Genotic, we are committed to providing the support and resources you need to make the most of our products. Our support team is here to ensure you have access to the tools, information, and guidance required to achieve successful results.",
    button: "Go back",
  },
  lab: {
    title: "Laboratorium",
    hero: {
      title: "Produkcja przeciwciał i kontrola jakości w naszym laboratorium",
      description:
        "Wierzymy w przejrzystość naszych metod produkcji. Ten przegląd opisuje nasz proces produkcyjny - od początkowego klonowania do oczyszczania i kontroli jakości - pokazując, jak zapewniamy spójność w każdej partii.",
    },
    process: {
      steps: {
        1: {
          title: "Klonowanie",
          description:
            "Nasze algorytmy AI projektują regiony zmienne przeciwciał, które są specjalnie dostosowane do wiązania wybranych antygenów z wysoką specyficznością. Te regiony zmienne są następnie klonowane do wybranego wektora.",
          list: [],
          notes: [
            "Na tym etapie możemy dostosować region stały przeciwciała (np. ludzki, mysi lub króliczego łańcucha ciężkiego) zgodnie z pożądanymi specyfikacjami produktu.",
            "Zaprojektowane regiony zmienne mogą być łączone z dowolnym wybranym łańcuchem ciężkim. Powstałe wektory są amplifikowane w bakteriach E. coli w celu uzyskania wysokiej wydajności dla kolejnych procesów.",
          ],
        },
        2: {
          title: "Produkcja",
          description:
            "Kolejny krok obejmuje transfekcję komórek bakteryjnych lub transformację komórek ssaków w celu umożliwienia produkcji pożądanego przeciwciała.",
          list: [],
          notes: [
            "Poprzez optymalizację warunków hodowli i protokołów stymulacji możemy znacząco zwiększyć wydajność produkcji. Ten etap zapewnia skalowalność i powtarzalność w generowaniu wysokiej jakości przeciwciał.",
          ],
        },
        3: {
          title: "Oczyszczanie",
          description:
            "Oczyszczanie przeciwciał przeprowadzane jest przy użyciu technik szybkiej chromatografii cieczowej białek (FPLC). Każde przeciwciało przechodzi rygorystyczny dwu- lub trzystopniowy proces oczyszczania, obejmujący typowo:",
          list: [
            "Chromatografia powinowactwa do metalu immobilizowanego (IMAC)",
            "Chromatografia jonowymienna (IEX)",
            "Chromatografia wykluczania molekularnego (SEC)",
          ],
          notes: [
            "To wieloetapowe podejście gwarantuje usunięcie zanieczyszczeń i zapewnia produkcję przeciwciał o najwyższej czystości.",
          ],
        },
        4: {
          title: "Kontrola Jakości",
          description:
            "Każde przeciwciało poddawane jest kompleksowym testom kontroli jakości w celu zapewnienia spójności i wydajności:",
          list: [
            {
              term: "Spektrometria mas:",
              definition: "Stosowana do potwierdzenia sekwencji aminokwasowej przeciwciała.",
            },
            {
              term: "SDS-PAGE i Western Blot (WB):",
              definition: "Te techniki weryfikują masę cząsteczkową przeciwciała.",
            },
            {
              term: "Kinetyka wiązania:",
              definition:
                "Analizowana jest interakcja między przeciwciałem a jego antygenem docelowym, a stała dysocjacji (K𝐷) jest określana przy użyciu interferometrii biolayer (BLI).",
            },
          ],
          notes: [
            "Ta rygorystyczna kontrola jakości zapewnia niezawodność funkcjonalną naszych produktów.",
          ],
        },
        5: {
          title: "Koniugacja",
          description:
            "Dla określonych zastosowań, wybrane przeciwciała są koniugowane z fluoroforami lub enzymami, co zwiększa ich użyteczność w technikach detekcji i analizy.",
          list: [],
          notes: [],
        },
        6: {
          title: "Przechowywanie",
          description:
            "Gotowe przeciwciała są zazwyczaj liofilizowane i przechowywane w stanie zamrożonym w celu zachowania ich stabilności i funkcjonalności przez dłuższy czas.",
          list: [],
          notes: [],
        },
      },
    },
    excellence: {
      title: "Zobowiązanie do doskonałości",
      heading:
        "Każdy etap produkcji przeciwciał w naszym laboratorium jest przeprowadzany przy ścisłym przestrzeganiu ustalonych protokołów.",
      description:
        "Zapewnia to wysoką powtarzalność i wyjątkową jakość wszystkich naszych produktów, spełniając wymagania badań i zastosowań klinicznych.",
    },
  },
  services: {
    title: "Usługi",
    hero: {
      title: "Natychmiastowe projektowanie",
      description: "Potrzebujesz przeciwciała, którego nie możesz znaleźć?",
      subDescription:
        "Projektujemy spersonalizowane przeciwciała in-silico, dostarczając produkt gotowy do użycia w 20 dni.",
    },
    process: {
      steps: {
        1: {
          title: "Prześlij swoją strukturę",
          description:
            "Wszystko, czego potrzebujemy, to plik .pdb ze strukturą 3D, do której ma się wiązać przeciwciało.",
        },
        2: {
          title: "Opłacalna procedura",
          description:
            "Po ocenie możliwości stworzenia spersonalizowanego produktu, poprosimy o wpłatę 3500 €. Wtedy możemy zacząć produkcję!",
        },
        3: {
          title: "Projektowanie przez AI",
          description:
            "Używając 200 GPU i naszych algorytmów AI, obliczymy najlepszych kandydatów dla Twojego celu.",
        },
        4: {
          title: "Od in-silico do laboratorium",
          description:
            "Starannie wyprodukujemy najbardziej obiecujących kandydatów w naszym własnym laboratorium.",
        },
        5: {
          title: "Specyficzne i precyzyjne przeciwciała",
          description:
            "Aby zagwarantować najwyższą jakość naszych produktów, nasze produkty są testowane i mają minimalną czystość 90% określoną przez wyniki SDS-PAGE. Dodatkowo wykonujemy spektrometrię mas, upewniamy się, że analityczna faza odwrócona ma pojedynczy i ostry pik, a endotoksyny są poniżej poziomu wykrywalności.",
        },
        6: {
          title: "Gwarancja bez ryzyka",
          description:
            "Aby zagwarantować najwyższą jakość naszych produktów, nasze produkty są testowane i mają minimalną czystość 90% określoną przez wyniki SDS-PAGE. Dodatkowo wykonujemy spektrometrię mas, upewniamy się, że analityczna faza odwrócona ma pojedynczy i ostry pik, a endotoksyny są poniżej poziomu wykrywalności.",
        },
      },
      confirmation: "W ciągu 48 godzin potwierdzimy, czy możemy stworzyć przeciwciało",
      delivery: "Po maksymalnie 3 tygodniach otrzymasz przeciwciało",
    },
    contact: {
      title: "Skontaktuj się z nami",
      requestTitle: "Zamów spersonalizowane przeciwciało",
      description:
        "Opowiedz nam o swoim projekcie. Dostarczymy szczegółowe instrukcje dotyczące zgłoszenia.",
      email: "contact@genotic.com",
    },
    communication: {
      title: "Przejrzysta komunikacja",
      description:
        "Podczas trzytygodniowego procesu produkcji Twoich spersonalizowanych przeciwciał, dostarczamy regularne aktualizacje na temat każdego etapu produkcji, od projektowania po końcowe oczyszczanie. Nasz zautomatyzowany system powiadomień informuje Cię o kluczowych etapach produkcji i wynikach kontroli jakości.",
    },
    faq: {
      title: "Często zadawane pytania",
      items: [
        {
          question: "Jaka dokumentacja jest dostarczana z produktem końcowym?",
          answer:
            "Każde przeciwciało jest dostarczane z kompletnym pakietem dokumentacji zawierającym analizę czystości (SDS-PAGE), wyniki spektrometrii mas i dane z analitycznej chromatografii z odwróconą fazą. Dostarczamy również szczegółowe informacje o warunkach przechowywania i zalecenia dotyczące obchodzenia się z produktem.",
        },
        {
          question: "Jaki jest wskaźnik sukcesu w projektowaniu spersonalizowanych przeciwciał?",
          answer:
            "Nasze podejście oparte na AI ma ponad 90% wskaźnik sukcesu w tworzeniu przeciwciał, które spełniają lub przekraczają określone wymagania wiązania. Jeśli stwierdzimy, że nie możemy stworzyć przeciwciała spełniającego Twoje potrzeby, poinformujemy Cię o tym w ciągu 48 godzin i zapewnimy pełny zwrot kosztów.",
        },
        {
          question: "Czy możecie projektować przeciwciała dla złożonych lub trudnych celów?",
          answer:
            "Tak, nasze algorytmy AI są szczególnie skuteczne w przypadku złożonych celów. Z powodzeniem zaprojektowaliśmy przeciwciała dla tradycyjnie trudnych celów, w tym białek błonowych, epitopów konformacyjnych i wysoce podobnych wariantów białek.",
        },
        {
          question: "Co się stanie, jeśli przeciwciało nie spełni specyfikacji?",
          answer:
            "Oferujemy 100% gwarancję satysfakcji. Jeśli przeciwciało nie spełni uzgodnionych specyfikacji, przeprojektujemy i wyproduktujemy nowe przeciwciało bez dodatkowych kosztów lub zapewnimy pełny zwrot kosztów.",
        },
      ],
    },
  },
  about: {
    title: "O nas",
    intro:
      "Jesteśmy Genotic - zespołem naukowców i inżynierów pracujących na styku sztucznej inteligencji i projektowania przeciwciał. Naszą misją jest rozszerzanie możliwości w badaniach nauk przyrodniczych poprzez połączenie głębokiej wiedzy naukowej z innowacjami obliczeniowymi.",
    values: [
      {
        name: "Nauka.",
        description:
          "Projektujemy spersonalizowane przeciwciała dla każdego celu, w tym tych pomijanych przez tradycyjnych producentów. Naszym celem jest umożliwienie badań w niezbadanych dziedzinach.",
      },
      {
        name: "Innowacja.",
        description:
          "Zastąpiliśmy miesiące prób i błędów w laboratorium precyzją obliczeniową. Nasza sztuczna inteligencja projektuje gotowe do użycia przeciwciała w tygodnie, nie w lata.",
      },
      {
        name: "Postęp.",
        description:
          "Rozwijamy się dzięki zwalidowanym wynikom, nie obietnicom. Każda zwalidowana implementacja prowadzi nas do nowych możliwości.",
      },
    ],
    mission: {
      title: "Nasza Misja",
      paragraph1:
        "Dążymy do wykorzystania rewolucyjnego potencjału sztucznej inteligencji, szczególnie wyrafinowania dużych modeli językowych i dynamizmu grafowych splotowych sieci neuronowych, aby wytyczyć kurs naszej wspólnej przyszłości.",
      paragraph2:
        "Jesteśmy przekonani o nadzwyczajnej zdolności sztucznej inteligencji do projektowania nowych przeciwciał, przyspieszania tempa odkryć naukowych i zwalczania chorób zagrażających życiu. W tych ogromnych możliwościach widzimy obietnicę uzdrowienia i nadziei dla całej ludzkości.",
    },
    beyondBusiness: {
      title: "Poza Biznesem",
      commercial: {
        title: "Sukces komercyjny napędzający postęp naukowy.",
        description:
          "Wierzymy, że sukces komercyjny i postęp naukowy mogą się wzajemnie wzmacniać. Nasz model biznesowy jest zbudowany wokół ciągłego postępu naukowego, pozwalając nam eksplorować nowe granice w projektowaniu obliczeniowym, wspierając jednocześnie szersze inicjatywy badawcze. To podejście utrzymuje nas skupionych na długoterminowym wpływie naukowym zamiast na krótkoterminowych zyskach.",
      },
      science: {
        title: "Ograniczony budżet nie powinien ograniczać wielkiej nauki.",
        description:
          "Chociaż działamy jako podmiot komercyjny, aktywnie poszukujemy partnerstw z naukowcami badającymi obiecujące, ale niedofinansowane obszary. Jeśli widzisz potencjał wykorzystania naszej technologii w swoich badaniach, zachęcamy do kontaktu niezależnie od Twojej sytuacji finansowej.",
      },
    },
    contact: {
      title: "Kontakt",
      subtitle: "Omów Możliwości Współpracy",
    },
    numbers: {
      title: "Genotic w Liczbach",
      items: [
        {
          unit: "",
          description: "Zaprojektowane przeciwciała",
        },
        {
          unit: "dni",
          description: "Czas produkcji spersonalizowanych przeciwciał",
        },
        {
          unit: "lat",
          description: "Doświadczenie w projektowaniu przeciwciał",
        },
      ],
    },
    guarantee: {
      title: "Nasza Gwarancja",
      subtitle: "W sercu naszej firmy leży niezachwiane zaangażowanie w jakość.",
      description:
        "Gwarantujemy skuteczność każdego produkowanego przez nas przeciwciała, ponieważ rozumiemy, że niezawodne narzędzia są fundamentalne dla sukcesu badań. To nie jest tylko standard jakości - to nasza podstawowa zasada, która kieruje wszystkim, co robimy.",
    },
  },
  home: {
    hero: {
      title: "Rewolucja przeciwciał AI",
      description:
        "Uzyskaj dostęp do naszej rosnącej biblioteki ponad 1200 przeciwciał zaprojektowanych przez AI. Potrzebujesz czegoś konkretnego? Możemy zaprojektować spersonalizowane przeciwciała dla Twoich unikalnych celów.",
    },
    ourApproach: {
      title: "Nasze podejście",
      items: [
        {
          title: "Projektowanie AI",
          description:
            "Algorytmy AI umożliwiają szybsze projektowanie standardowych przeciwciał i dostosowanych rozwiązań dla nowych celów.",
        },
        {
          title: "Szybka produkcja",
          description:
            "Nasze procesy projektowania i produkcji pozwalają nam tworzyć nowe przeciwciała w ciągu tygodni, nie lat.",
        },
        {
          title: "Projektowanie bez zwierząt",
          description:
            "Wykorzystując techniki obliczeniowe, zapewniamy etyczne projektowanie produktów, eliminując eksperymenty na zwierzętach.",
        },
        {
          title: "Gwarantowana jakość",
          description:
            "Nasze produkty przechodzą rygorystyczne testy z wykorzystaniem procedur takich jak SDS-PAGE, Western Blot czy spektrometria mas.",
        },
      ],
    },
    products: {
      title: "Nasze produkty",
      categories: {
        antibodies: "Przeciwciała",
        proteins: "Białka",
        enzymes: "Enzymy",
      },
    },
    testimonials: {
      title: "Referencje",
      navigation: {
        previous: "Poprzednia opinia",
        next: "Następna opinia",
      },
    },
    designOnTheFly: {
      title: "Projektowanie na żądanie",
      heading: "Zamów spersonalizowane przeciwciała",
      description:
        "Uzyskaj dostęp do naszej rosnącej biblioteki ponad 1200 przeciwciał zaprojektowanych przez AI. Potrzebujesz czegoś konkretnego? Możemy zaprojektować spersonalizowane przeciwciała dla Twoich unikalnych celów.",
      learnMore: "Dowiedz się więcej",
    },
  },
  loginPage: {
    title: "Zaloguj się",
    description:
      "W Genotic zobowiązujemy się do zapewnienia wsparcia i zasobów, których potrzebujesz, aby jak najlepiej wykorzystać nasze produkty.",
  },
} satisfies I18nLocale;
